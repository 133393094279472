import React from "react";
import { Link } from "react-router-dom";
import { FiHome, FiMenu, FiShoppingCart, FiUser, FiBook } from "react-icons/fi";
import { ImNewspaper } from "react-icons/im";

import { Row, Col } from "react-bootstrap";

function MobileNavbar() {
  return (
    <Row className="d-lg-none navbar fixed-bottom navbar-light bg-light">
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/" >
          <FiHome style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/categories" >
          <FiMenu style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/cart" >
          <FiShoppingCart style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/news" >
          {/* <FiBook style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} /> */}
          <ImNewspaper style={{ fontSize: "25px", color: "gray" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/my-page" >
          <FiUser style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
    </Row>
  );
}

export default MobileNavbar;