import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function Login() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state);
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Email: ${userId}, Password: ${password}`);
    // /api/v2/auth/signIn
    fetch("https://cloud.1472.ai:18443/api/v2/auth/signIn", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idOrEmail: userId,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === false) {
          alert("아이디 또는 비밀번호가 일치하지 않습니다.");
        } else {
          getCmpny(data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getCmpny = (user) => {
    console.log(user);
    fetch(`https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${user.cmpnyNo}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        user.cmpnyNm = data.cmpnyNm;
        dispatch({ type: "LOGIN", payload: user });
        navigate(-1);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (userState.isLoggedIn) {
      navigate("/");
    }
  }, [userState, navigate]);

  return (
    <Container fluid className="vh-100">
      <Row className="justify-content-center align-items-center h-100">
        <Col sm={10} md={6} lg={3}>
          <div className="card shadow">
            <div className="card-body p-5">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Control
                    type="text"
                    placeholder="아이디"
                    value={userId}
                    onChange={(event) => setUserId(event.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formPassword">
                  <Form.Control
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" style={{ backgroundColor: "#F39802", border: 0 }} type="submit" className="w-100 mb-3">
                  로그인
                </Button>
                <p className="text-center mb-0">
                  계정이 없으신가요?{" "}
                  <Link to="#" className="text-decoration-none">
                    회원가입
                  </Link>
                </p>
              </Form>
              <hr className="my-4" />
              {/* <div className="d-grid gap-2">
                <Button variant="outline-primary" size="lg">
                  <i className="fab fa-facebook"></i> 페이스북으로 로그인
                </Button>
                <Button variant="outline-primary" size="lg">
                  <i className="fab fa-google"></i> 구글로 로그인
                </Button>
              </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
