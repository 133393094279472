import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  Navbar,
  Container,
  Form,
  FormControl,
  Button,
  Dropdown,
  Row,
  Col,
  NavDropdown,
} from "react-bootstrap";
import {
  FaSearch,
  FaBars,
  FaTshirt,
  FaSpa,
  FaShoppingBasket,
  FaCouch,
  FaDesktop,
  FaFootballBall,
  FaBook,
  FaGlassCheers,
  FaAmbulance,
} from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { ImNewspaper } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const [btyList, setBtyList] = useState([]);


  const handleDropdownToggle = () => setShowDropdown(!showDropdown);
  const handleDropdownClose = () => setShowDropdown(false);

  useEffect(() => {
    console.log(userState);
    getBtyCode();
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getBtyCode = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clCode: "BTY",
        }),
      });

      const btyList = await response.json();
      const newBtyList = btyList
        .filter((item) => item.code !== "BTY011")
        .map((item) => addBtyListIcon(item));
      setBtyList(newBtyList);
    } catch (error) {
      console.error(error);
    }
  };

  const addBtyListIcon = (item) => {
    let icon;
    let subCategories;
    switch (item.code) {
      case "BTY002":
        icon = <FaTshirt />;
        break;
      case "BTY003":
        icon = <FaSpa />;
        break;
      case "BTY004":
        icon = <FaShoppingBasket />;
        break;
      case "BTY005":
        icon = <FaCouch />;
        subCategories = 1;
        break;
      case "BTY006":
        icon = <FaDesktop />;
        break;
      case "BTY007":
        icon = <FaFootballBall />;
        break;
      case "BTY008":
        icon = <FaBook />;
        break;
      case "BTY009":
        icon = <FaGlassCheers />;
        break;
      case "BTY010":
        icon = <FaAmbulance />;
        break;
      default:
        icon = null;
    }
    return { ...item, icon, subCategories };
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const query = event.target.search.value;
    if (!query) {
      toast.error("검색어를 입력해주세요.", {
        position: "top-center",
        duration: 2000,
        style: {
          marginTop: "50px",
          background: "black",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
        },
      });
    } else {
      navigate("/search-result", {
        state: { query: event.target.search.value },
      });
      document.querySelector('#search').value = "";
    }
  };

  const handleCategory = (item) => {
    console.log(item);
    if (!item) {
      toast.error("에러가 발생하였습니다 잠시후 다시 시도해주세요.", {
        position: "top-center",
        duration: 2000,
        style: {
          marginTop: "50px",
          background: "black",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
        },
      });
    } else {
      navigate("/search-result", {
        state: { cat: item.codeNm, catCode: item.code },
      });
      document.querySelector('#search').value = "";
    }
  }

  return (
    <>
      <header
        className="d-block d-lg-none"
        style={{
          position: "fixed",
          top: "0",
          height: "60px",
          width: "100%",
          zIndex: "9997",
          backgroundColor: "#fff",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Container style={{ paddingBottom: "20px" }}>
          <Navbar expand="lg">
            <Navbar.Brand href="/" style={{ width: '24%', marginRight: '0' }}>
              <img src={'/k-logo.jpeg'} alt={'logo'} style={{ width: '100%' }} />
            </Navbar.Brand>
            <Form
              className="d-flex"
              onSubmit={handleSearch}
              style={{ width: "70%" }}
            >
              <div className="position-relative w-100">
                <FormControl
                  type="search"
                  name="search"
                  placeholder="검색"
                  className="mr-2"
                  aria-label="Search"
                  style={{ width: "100%", paddingRight: "40px" }}
                />
                <Button
                  variant="link"
                  type="submit"
                  className="position-absolute"
                  style={{
                    top: "50%",
                    right: "0px",
                    transform: "translateY(-50%)",
                    color: "#F39802"
                  }}
                >
                  <FaSearch />
                </Button>
              </div>
            </Form>
          </Navbar>
        </Container>
      </header>

      <header
        className="d-none d-lg-block"
        style={{
          // top: "0",  // 필요없는 속성 제거
          height: "100px",
          width: "100%",
          zIndex: "9997",
          backgroundColor: "#fff",
        }}
      >
        <Container style={{ height: "100%", paddingBottom: "1px" }}>
          <Row style={{ height: "100%" }}>
            <Col xs={12} md={4} className="d-flex align-items-center">
              <Navbar.Brand
                href="/"
                style={{ fontSize: "30px", color: "black" }}
              >
                <img src={'/k-logo.jpeg'} alt={'logo'} style={{ width: '190px' }} />
              </Navbar.Brand>
            </Col>
            <Col xs={12} md={4} className="d-flex align-items-center">
              <Form className="w-100" onSubmit={handleSearch}>
                <div className="position-relative">
                  <FormControl
                    type="search"
                    name="search"
                    id="search"
                    placeholder="검색"
                    className="mr-2"
                    aria-label="Search"
                    style={{ width: "100%", paddingRight: "40px" }}
                  />
                  <Button
                    variant="link"
                    type="submit"
                    className="position-absolute"
                    style={{
                      top: "50%",
                      right: "0px",
                      transform: "translateY(-50%)",
                      color: "#F39802"
                    }}
                  >
                    <FaSearch />
                  </Button>
                </div>
              </Form>
            </Col>
            <Col
              xs={12}
              md={4}
              className="d-flex align-items-center justify-content-end"
            >
              <Link to="/news">
                <ImNewspaper style={{ fontSize: "35px", color: "gray", marginRight: "30px" }} />
              </Link>
              {isLoggedIn ? (
                <Dropdown
                  show={showDropdown}
                  onToggle={handleDropdownToggle}
                  onClose={handleDropdownClose}
                >
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    style={{ fontSize: "25px" }}
                  >
                    <AiOutlineUser />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" style={{ zIndex: "10000" }}>
                    <Dropdown.Item
                      onClick={() => {
                        handleDropdownClose();
                        navigate("/my-page");
                      }}
                    >
                      마이페이지
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDropdownClose();
                        navigate("/cart");
                      }}
                    >
                      장바구니
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDropdownClose();
                        dispatch({ type: "LOGOUT" });
                      }}
                    >
                      로그아웃
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <Link
                    to="/login"
                    style={{
                      color: "#212529",
                      fontSize: "20px",
                      marginRight: "10px",
                    }}
                  >
                    로그인
                  </Link>
                </>
              )}
            </Col>
          </Row>
          {/* 헤더 카테고리 영역 */}
          {/* <Row
            className="align-items-center"
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              position: isHeaderFixed ? "fixed" : "absolute",
              top: isHeaderFixed ? "0px" : "100px",
              width: "100%",
              zIndex: "9997",
              backgroundColor: "#fff",
              left: 0,
              justifyContent: "center",
            }}
          >
            <Col style={{ maxWidth: "1140px" }}>
              <Nav
                className="justify-content-start"
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <Nav.Item
                  style={{
                    marginRight: "200px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FaBars />
                  <NavDropdown
                    title="카테고리"
                    id="menuDropdown"
                    className="nav-dropdown"
                  >
                    {btyList.map((item) => (
                      <NavDropdown.Item
                        key={item.code}
                        onClick={() => handleCategory(item)}
                        style={{
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.icon} {item.codeNm}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  <Nav.Link href="/home" style={{ color: 'black' }}>카테고리</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-auto">
                  <Nav.Link href="/home" style={{ color: "#999999" }}>
                    카테고리
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-auto">
                  <Nav.Link href="/home" style={{ color: "#999999" }}>
                    카테고리
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-auto">
                  <Nav.Link href="/home" style={{ color: "#999999" }}>
                    카테고리
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-auto">
                  <Nav.Link href="/home" style={{ color: "#999999" }}>
                    카테고리
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-auto">
                  <Nav.Link href="/home" style={{ color: "#999999" }}>
                    카테고리
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row> */}
        </Container>
        <hr />
      </header>
    </>
  );
}

export default Header;
