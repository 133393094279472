import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
} from "react-bootstrap";
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs'

function Success() {
  const [searchParams] = useSearchParams()
  const [payment, setPayment] = useState([]);
  const [ordList, setOrdList] = useState([]);
  const [dtlList, setDtlList] = useState([]);
  const [ord, setOrd] = useState([]);

  const orderId = searchParams.get("orderId");
  const paymentKey = searchParams.get("paymentKey")
  const amount = searchParams.get("amount")
  const secretKey = 'test_sk_OEP59LybZ8Bdy9Q1kRWV6GYo7pRe:'

  const cmpnyNo = JSON.parse(sessionStorage.getItem('user')).cmpnyNo
  const ordNo = orderId.split('-')[1];

  useEffect(() => {
    getOrd();
    paymentsConfirm();
  }, []);

  useEffect(() => {
    if (0 == dtlList.length) {
      console.log("전송x")
    } else {
      console.log("전송o")
      UpdateOrd();
    }
  }, [dtlList]);

  // 결제승인
  const paymentsConfirm = async () => {
    try {
      const response = await fetch("https://api.tosspayments.com/v1/payments/confirm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(secretKey),
        },
        body: JSON.stringify({
          paymentKey: paymentKey,
          orderId: orderId,
          amount: amount,
        }),
      });
      const data = await response.json();
      console.log(data);
      getPayments();

    } catch (error) {
      console.error(error);
    }
  };

  // 결제정보
  const getPayments = async () => {
    try {
      const response = await fetch(`https://api.tosspayments.com/v1/payments/orders/${orderId}`, {
        method: "GET",
        headers: {
          "Authorization": "Basic " + btoa(secretKey)
        },
      });
      const data = await response.json();
      console.log(data);
      setPayment(data);
      orderList(data.orderId);
    } catch (error) {
      console.error(error);
    }
  };

  // 주문서 조회
  const orderList = async (orderId) => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordNo: ordNo,
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: "true"
        }),
      });
      const data = await response.json();
      console.log(data.content);
      const dummy = data.content;
      const newList = await Promise.all(dummy);
      setOrdList(data.content)

      newList.map((item) => {
        const ordDtlNo = item.ordDtlNo;
        const ordNo = item.ordNo;
        const newData = {
          ordDtlNo: ordDtlNo,
          ordNo: ordNo,
          prdNo: item.prdNo,
          prductNm: item.prductNm,
          setNo: 0,
          totAmount: item.totAmount,
          prductCl: "",
          qy: item.qy,
          cost: item.cost,
          m: 0,
          n: 0,
          supplyAmt: 0,
          tax: 0,
          ordQy: 0,
          bomSetSe: "1",
          asPd: 0,
          prmpc: 0,
          prmpcSm: 0,
          profitAmt: 0,
          selngEntrpsNo: 0,
          socNo: 0,
          dlivMatter: "",
          vendnm: "",
          delYn: "N",
          repImg: item.repImg,
          selngDataNo: 0,
          dlvPrpNo: "",
          ordReqNo: 0,
          setYn: "N",
        };
        setDtlList((list) => [...list, newData])
      })
    } catch (error) {
      console.error(error);
    }
  };

  let pdlPrice = 0;
  pdlPrice = ordList.reduce(
    (acc, item) => acc + item.totAmount,
    0
  );
  pdlPrice = pdlPrice.toLocaleString();

  let orderTime = "";
  let totalPrice = 0;
  if (typeof payment.requestedAt === 'undefined') {
  } else {
    orderTime = payment.requestedAt.slice(0, 10);
    totalPrice = payment.totalAmount.toLocaleString()
  }

  const UpdateOrd = () => {
    const nowTime = dayjs().format("YYYY-MM-DD HH:mm:ss")
    fetch("https://erp.1472.ai:28443/api/v1/ord", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ordVo: {
          dlvySptAdres: "",
          // dlvySptNm: '',
          dtlList: dtlList,
          ordNo: ordList[0].ordNo,
          ordCmpnyNo: cmpnyNo, // 주문회사번호
          ordMberNo: 1, // 주문회원번호
          wrter: 1, // 작성자
          ordSttusCl: "ORDS02", // 주문상태분류
          progrsSttus: "OWS012", // 진행상태
          writeDt: nowTime, // 작성일시
          ordDt: nowTime, // 주문일시
          dedtDe: nowTime, // 납기일자
          bcncNo: ord[0].bcncNo, // 거래처번호
          bcncNm: ord[0].bcncNm, // 거래처명
          bcncTelno: ord[0].bcncTelno, // 거래처전화번호
          sptNm: ord[0].sptNm, // 현장명 넣기
          sptAdres: ord[0].sptNm, // 현장주소 넣기
          dlvyMth: "택배", // 배송방법
          dlvyCt: "선불", // 배송비
          rcpnt: ord[0].rcpnt, // 수령자
          dept: "없음", // 부서
          vatCl: "VATS03", // 부가세분류
          demandTot: 0, // 출고액합계
          trnsprtDvyfgAm: 0, // 운송,납품액
          bndePrductCl: "전체", // 일괄제품분류
          bndeMargin: 0, // 일괄마진
          bndeNego: 0, // 일괄네고
          sumry: "1", // 적요
          ordCours: "OCR001", // 주문경로
          ordCrcmstncs: "1", // 주문경위
          buyNo: 0, // 매입관리번호
          selngNo: 0, // 매출관리번호
          faxYn: "N", // 팩스여부
          prtYn: "N", // 출력여부
          dlivyYn: "N", // 출고여부
          bilclctYn: "N", // 수금여부
          delYn: "N", // 삭제여부
          schRegYn: "N" // 일정등록여부
        }
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.error(error));
  }

  const getOrd = async () => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordNo: ordNo,
          ordCmpnyNo: cmpnyNo,
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      });
      const data = await response.json();
      console.log(data);
      const dummy = data.content;
      const newList = await Promise.all(dummy);
      setOrd(newList);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container
      className="py-5"
      style={{ marginTop: "5em", marginBottom: "5em" }}
    >
      <h2>주문완료</h2>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">상품정보</h4>
          <Card style={{ border: 0 }}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>이미지</th>
                  <th style={{ width: "60%" }}>상품명</th>
                  <th style={{ whiteSpace: "nowrap" }}>수량</th>
                  <th style={{ whiteSpace: "nowrap" }}>상품금액</th>
                </tr>
              </thead>
              <tbody>
                {
                  ordList.map((item) => (
                    <tr key={item.prdNo}>
                      <td><img style={{ width: 100 }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.repImg}`} /></td>
                      <td className='text-center'>{item.prductNm}</td>
                      <td>{item.qy}</td>
                      <td className='text-center'>{(item.totAmount).toLocaleString()}원</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">주문정보</h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>이름</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.rcpnt ? ord[0].rcpnt : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>번호</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.bcncTelno ? ord[0].bcncTelno : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>배송지</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.sptNm ? ord[0].sptNm : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>상태</td>
                    <td colSpan={7} className="text-right">
                      결제완료
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">결제정보</h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>주문일자</td>
                    <td colSpan={7} className="text-right">
                      {orderTime}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ whiteSpace: "nowrap" }}>총 상품금액</td>
                    <td colSpan={7} className="text-right">
                      {pdlPrice}원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ whiteSpace: "nowrap" }}>배송비</td>
                    <td colSpan={7} className="text-right">
                      2,500원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ whiteSpace: "nowrap" }}>총 결제금액</td>
                    <td colSpan={7} className="text-right">
                      {totalPrice}원
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};

export default Success;