import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ProductDetailImage from "./ProductDetailImage";
import ProductDetailInfo from "./ProductDetailInfo";
import ProductDetailContents from "./ProductDetailContents";
import ProductMap from "../map/ProductMap";

function ProductDetail() {
  const location = useLocation();
  const product = location.state?.product || "";
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [productDetail, setProductList] = useState("");

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  const getProductDetail = async () => {
    try {
      const response = await fetch(
        `https://cloud.1472.ai:18443/api/v2/prdSynth/${product.prdNo}`,
        {
          method: "POST",
          headers: {
            Accept: "*/*",
          },
        }
      );

      const prdDetail = await response.json();
      console.log(prdDetail);
      setProductList(prdDetail);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main>
      <Container
        className="py-5"
        style={{ marginBottom: "60px"}}
      >
        <Row className="mt-5">
          <Col lg={6}>
            <ProductDetailImage
              currentImageIndex={currentImageIndex}
              onImageClick={handleImageClick}
              prdDetail={productDetail}
            />
          </Col>
          <Col lg={6}>
            <ProductDetailInfo prdDetail={product} />
          </Col>
        </Row>
        <ProductMap prd={product} />
        <ProductDetailContents prdDetail={productDetail} />
      </Container>
    </main>
  );
}

export default ProductDetail;
