import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import FeaturedProducts from "./FeaturedProducts";
import { useNavigate } from "react-router-dom";
import Loading from "./common/Loading";
import Slider from "react-slick";
import PrevArrow from "./common/PrevArrow";
import NextArrow from "./common/NextArrow";
import MainMap from "./map/MainMap";


function Main() {
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState([true, true, true, true]);
  const [mapLoading, setMapLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState([]);
  const [random, setRandom] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // 화면 크기가 변경될 때마다 실행되는 이벤트 리스너 추가
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 슬라이드 세팅
  const settings = {
    className: "center",
    arrow: true,
    infinite: true,
    slidesToShow: 5,
    speed: 400,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const bannerSettings = {
    className: "center",
    arrow: true,
    infinite: true,
    slidesToShow: 1,
    speed: 400,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    // 모바일 또는 데스크톱에 따라 다른 이미지 URL 선택
    if (isMobile) {
      setCurrentImage([
        '/banner/banner_m.jpeg',
      ]);
    } else {
      setCurrentImage([
        '/banner/banner_w.jpeg',
      ]);
    }
  }, [isMobile]);

  const pickRandomNumbers = () => {
    let numbers = [];
    while (numbers.length < 3) {
      let randomNum = Math.floor(Math.random() * 101);
      if (!numbers.includes(randomNum)) {
        numbers.push(randomNum);
      }
    }
    console.log(numbers);
    setRandom(numbers);
  };

  const handleImageLoad = (index) => {
    setIsLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  useEffect(() => {
    pickRandomNumbers();
    getProrudct();
  }, []);

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  const getProrudct = async () => {
    try {
      const response = await fetch(
        "https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            klightingYn: 'Y',
            offset: 0,
            pageNumber: 0,
            pageSize: 150,
            paged: false,
          }),
        }
      );

      const prdList = await response.json();
      const dummy = prdList.content;
      const newPrdListPromises = dummy.map((item) => getCmpnyNm(item));
      const newPrdList = await Promise.all(newPrdListPromises);
      const prdListFiltered = newPrdList.filter((item) => item.prdImgFlpth !== null);
      console.log(newPrdList);
      console.log(prdListFiltered)
      setProductList(prdListFiltered);
      setMapLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getCmpnyNm = async (item) => {
    const url = `https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${item.cmpnyNo}`;

    return fetch(url, { method: "POST", headers: { accept: "*/*" } })
      .then((response) => response.json())
      .then((data) => {
        const infoCmpny = data;
        return { ...item, infoCmpny };
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  return (
    <main className="py-5" style={{ marginBottom: "5em", marginTop: "3em" }}>
      <div>
        <Slider {...bannerSettings}>
          {currentImage.map((image, index) => (
            <div className="banner__slide" key={index}>
              <img src={image} alt={`banner ${index + 1}`} style={{ width: '100%' }} className="banner" />
            </div>
          ))}
        </Slider>
      </div>
      <Container>
        <div style={{ marginTop: "3rem" }}>
          <Slider {...settings}>
            {
              productList
                .slice(random[1] - 30, random[1] - 20)
                .map((product, index) => (
                  <div key={index}
                    style={{ flex: "0 0 200px", marginRight: "20px", border: "3px solid black" }}>
                    <Card onClick={() => handleClick(product)} style={{ border: 0 }}>
                      {isLoading[index] && (
                        <div style={{ height: "150px" }}>
                          <Loading />
                        </div>
                      )}
                      <Card.Img
                        onLoad={() => handleImageLoad(index)}
                        style={{
                          width: "150px",
                          height: "150px",
                          margin: "0 auto",
                          objectFit: "cover",
                          display: isLoading[index] ? "none" : "block",
                        }}
                        src={
                          product?.prdImgFlpth
                            ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                            : product?.extrlImgUrl
                        }
                      />
                      <Card.Body style={{ textAlign: "left", padding: "0 30px" }}>
                        <Card.Text style={{ fontSize: "12px" }}>
                          {/* {product?.infoCmpny.cmpnyNm || ""} */}
                          <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{product?.infoCmpny.cmpnyNm}</span>
                        </Card.Text>
                        <Card.Title style={{ fontSize: "15px" }}>
                          {product?.prductNm.length > 20
                            ? product?.prductNm.substring(0, 20) + ".."
                            : product?.prductNm || ""}
                        </Card.Title>
                        <Card.Text style={{ fontSize: "14px", fontWeight: 700 }}>
                          {(product?.klightingPdCmPrice ?? product?.cnsmrPc).toLocaleString()}원
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))
            }
          </Slider>
        </div>


        <FeaturedProducts productList={productList} />

        <Row className="mt-5">
          {productList
            .slice(random[1] - 5, random[1] - 1)
            .map((product, index) => (
              <Col key={index} xs={6} md={3} className="mb-5">
                {/* <Card onClick={handleClick}> */}
                <Card onClick={() => handleClick(product)} style={{ height: "80%", border: 0 }}>
                  {isLoading[index] && (
                    <div
                      style={{
                        height: "150px",
                      }}
                    >
                      <Loading />
                    </div>
                  )}
                  <Card.Img
                    onLoad={() => handleImageLoad(index)}
                    style={{
                      objectFit: "contain",
                      display: isLoading[index] ? "none" : "block",
                      height: "90%"
                    }}
                    src={
                      product?.prdImgFlpth
                        ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                        : product?.extrlImgUrl
                    }
                  />
                  <Card.Body style={{ textAlign: "left", padding: 0 }}>
                    <Card.Text style={{ fontSize: "12px" }}>
                      <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{product?.infoCmpny.cmpnyNm}</span>
                    </Card.Text>
                    <Card.Title style={{ fontSize: "15px" }}>
                      {product?.prductNm.length > 16
                        ? product?.prductNm.substring(0, 16) + ".."
                        : product?.prductNm || ""}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "18px", fontWeight: 700 }}>
                      {(product?.klightingPdCmPrice ?? product?.cnsmrPc).toLocaleString()}원
                    </Card.Text>
                  </Card.Body>
                </Card>
                {/* <Card.Footer style={{ textAlign: "left", padding: "10px 0", border: 0 }}>
                  <Card.Text style={{ fontSize: "12px", color: "rgb(153, 153, 153)" }}>
                    {product?.infoCmpny.cmpnyNm}
                  </Card.Text>
                  <Card.Title style={{ fontSize: "15px" }}>
                    {product?.prductNm.length > 16
                      ? product?.prductNm.substring(0, 16) + ".."
                      : product?.prductNm || ""}
                  </Card.Title>
                  <Card.Text style={{ fontSize: "18px", fontWeight: 700 }}>
                    {product?.pdCmPrice.toLocaleString()}원
                  </Card.Text>
                </Card.Footer> */}
              </Col>
            ))}
        </Row>
      </Container>
    </main>
  );
}

export default Main;
