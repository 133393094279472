import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
  Button,
  ListGroup,
} from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';

const OrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [ordList, setOrdList] = useState([]);
  const [ord, setOrd] = useState([]);

  const cmpnyNo = JSON.parse(sessionStorage.getItem("user")).cmpnyNo;

  useEffect(() => {
    getOrd();
    orderList();
  }, [])

  const getOrd = async () => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordNo: location.state.orderNum[0],
          ordCmpnyNo: cmpnyNo,
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      });
      const data = await response.json();
      console.log(data);
      const dummy = data.content;
      const newList = await Promise.all(dummy);
      setOrd(newList);
    } catch (error) {
      console.error(error);
    }
  }

  // 주문서 조회
  const orderList = async (orderId) => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordNo: location.state.orderNum[0],
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: "true"
        }),
      });
      const data = await response.json();
      console.log(data.content);
      setOrdList(data.content)
    } catch (error) {
      console.error(error);
    }
  };

  let totalPrice = 0;
  totalPrice = ordList.reduce(
    (acc, item) => acc + item.totAmount,
    0
  );

  const handleClick = async (item) => {
    const prdNo = item.prdNo.split("_")[0];
    let infoCmpny = "";
    let pdStock = "";
    try {
      const response = await fetch(`https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${ord[0].bcncNo}`, {
        method: "POST",
        headers: {
          accept: "*/*"
        }
      });
      const data = await response.json();
      infoCmpny = data;

      const response2 = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          cmpnyNo: infoCmpny.cmpnyNo,
          klightingYn: 'Y',
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      })
      const data2 = await response2.json();
      pdStock = data2.content[0];

    } catch (error) {
      console.error(error);
    }
    const product = {
      brand: pdStock.brand,
      cmpnyNo: pdStock.cmpnyNo,
      infoCmpny: infoCmpny,
      pdCmPrice: item.cost,
      prdImgFlpth: item.repImg,
      prdNo: prdNo,
      prductNm: item.prductNm,
    }
    navigate("/product-detail", {
      state: { product: product }
    })
  }

  return (
    <Container className="py-5"
      style={{ marginTop: "5em", marginBottom: "5em" }}>
      <h2>상세주문</h2>
      <Row className="mb-5">
        <Col>
          <h4 className="text-left">상품정보</h4>
          <Card style={{ border: 0 }}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>이미지</th>
                  <th style={{ width: "60%" }}>상품명</th>
                  <th style={{ whiteSpace: "nowrap" }}>수량</th>
                  <th style={{ whiteSpace: "nowrap" }}>상품금액</th>
                </tr>
              </thead>
              <tbody>
                {
                  ordList.map((item) => (
                    <tr key={item.prdNo}>
                      <td><img onClick={() => {
                        handleClick(item);
                      }} style={{ width: 100, cursor: "pointer" }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.repImg}`} /></td>
                      <td style={{ cursor: "pointer" }} onClick={() => {
                        handleClick(item);
                      }} className='text-center'>{item.prductNm}</td>
                      <td>{item.qy}</td>
                      <td className='text-center'>{(item.totAmount).toLocaleString()}원</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">주문정보</h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>이름</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.rcpnt ? ord[0].rcpnt : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>번호</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.bcncTelno ? ord[0].bcncTelno : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>배송지</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.sptNm ? ord[0].sptNm : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>상태</td>
                    <td colSpan={7} className="text-right">
                      결제완료
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">결제정보</h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>주문일자</td>
                    <td colSpan={7} className="text-right">
                      {/* {orderTime} */}
                      {ord ? (ord[0] ? ord[0].dedtDe : '') : ''}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ whiteSpace: "nowrap" }}>총 상품금액</td>
                    <td colSpan={7} className="text-right">
                      {totalPrice}원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ whiteSpace: "nowrap" }}>배송비</td>
                    <td colSpan={7} className="text-right">
                      2,500원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ whiteSpace: "nowrap" }}>총 결제금액</td>
                    <td colSpan={7} className="text-right">
                      {(totalPrice + 2500).toLocaleString()}원
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetail;