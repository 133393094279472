import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loading from "../common/Loading";
import { useNavigate } from "react-router-dom";
import Map from "../map/Map";

const SearchResults = () => {
  const location = useLocation();
  const query = location.state?.query || null;
  const cat = location.state?.cat || "";
  const catCode = location.state?.catCode || "";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [productList, setProductList] = useState([]);

  const updateProductList = (newProductList) => {
    setProductList(newProductList);
  };

  useEffect(() => {
    setIsLoading(true);
    getProrudct();
  }, [catCode, query]);

  const getProrudct = async () => {
    try {
      const tempJson = {
        klightingYn: 'Y',
        offset: 0,
        pageNumber: 0,
        pageSize: 100000,
        paged: true,
      };
      if (catCode) {
        tempJson.bsnsTy = catCode;
      }
      if (query) {
        tempJson.prductNm = query;
      }
      const response = await fetch(
        "https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tempJson),
        }
      );

      const prdList = await response.json();
      const dummy = prdList.content;
      const newPrdListPromises = dummy.map((item) => getCmpnyNm(item));
      const newPrdList = await Promise.all(newPrdListPromises);
      console.log(newPrdList);
      setProductList(newPrdList);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getCmpnyNm = async (item) => {
    const url = `https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${item.cmpnyNo}`;

    return fetch(url, { method: "POST", headers: { accept: "*/*" } })
      .then((response) => response.json())
      .then((data) => {
        const infoCmpny = data;
        return { ...item, infoCmpny };
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  if (productList.length === 0) {
    return (
      <Container style={{ marginTop: "7em" }}>
        <Row>
          <Col>
            {query ? (
              <h2 className="mb-4">'{query}'검색결과가 없습니다.</h2>
            ) : (
              <h2 className="mb-4">
                [{cat}] <br />
                상품이 존재하지 않습니다.
              </h2>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: "7em", marginBottom: "7em" }}>
      <Row>
        <Col>
          {query ? (
            <h4 className="mb-4">'{query}'에 대한 검색결과</h4>
          ) : (
            <h2 className="mb-4">{cat}</h2>
          )}
        </Col>
      </Row>
      <Map productList={productList} updateProductList={updateProductList} query={query} />
      {/* <Row className="mt-4">
        {productList.map((product) => (
          <Col
            key={`${product.prdNo}|${product.cmpnyNo}`}
            mb={5}
            md={4}
            mt={5}
            style={{ marginTop: "1.5em" }}
          >
            <Card onClick={() => handleClick(product)}>
              <div style={{ height: "300px", overflow: "hidden" }}>
                <Card.Img
                  variant="top"
                  src={
                    product?.prdImgFlpth
                      ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                      : product?.extrlImgUrl
                  }
                  style={{ objectFit: "cover", height: "100%", width: "100%" }}
                />
              </div>
              <Card.Body style={{ textAlign: "left" }}>
                <Card.Text style={{ color: "rgb(153, 153, 153)" }}>
                  {product.infoCmpny.cmpnyNm}
                  <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{product?.infoCmpny.cmpnyNm}</span>
                </Card.Text>
                <Card.Title>{product.prductNm}</Card.Title>
                <Card.Text>{product.pdCmPrice.toLocaleString()}원</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row> */}
      <Row>
        {productList.map((product) => (
          <Col
            key={`${product.prdNo}|${product.cmpnyNo}`}
            md={3}
            xs={6}
            style={{ marginTop: "1.7em" }}
          >
            <Card onClick={() => handleClick(product)} style={{ height: "80%", border: 0 }}>
              <Card.Img
                variant="top"
                src={
                  product?.prdImgFlpth
                    ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                    : product?.extrlImgUrl
                }
                style={{ objectFit: "contain", height: "90%" }}
              />
              <Card.Body style={{ textAlign: "left", padding: "0" }}>
                <Card.Text style={{ fontSize: "13px" }}>
                  {/* {product.infoCmpny.cmpnyNm} */}
                  <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{product?.infoCmpny.cmpnyNm}</span>
                </Card.Text>
                <Card.Title style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  marginBottom: "8px",
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>{product.prductNm}</Card.Title>
                <Card.Text style={{ fontSize: "18px", fontWeight: "700" }}>
                  {(product?.klightingPdCmPrice ?? product?.cnsmrPc).toLocaleString()}원
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SearchResults;