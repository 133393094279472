import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../product/styles/productMap.css";

function ProductMap({ prd }) {

  // const [map, setMap] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    const SERVER_APP_KEY = "9c5596b3875cec638b4d2175ab09a688";
    const LOCAL_APP_KEY = "8400068f9c2268cfa77c552875a9ac80";
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${SERVER_APP_KEY}&autoload=false&libraries=services`;
    script.onload = () => {
      window.kakao.maps.load(() => {
        const { kakao } = window;
        const container = document.getElementById("map");
        const geocoder = new window.kakao.maps.services.Geocoder();
        geocoder.addressSearch(
          `${prd.infoCmpny.adres || '둔산남로 176'}`,
          (result, status) => {
            if (status === window.kakao.maps.services.Status.OK && result.length > 0) {
              const options = {
                center: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                level: 3,
              };
              const map = new window.kakao.maps.Map(container, options);
              const markerPosition = new window.kakao.maps.LatLng(
                result[0].y,
                result[0].x
              );
              let phone = "";
              if (!prd.infoCmpny.telno) {
                phone = "번호없음";
              } else {
                phone = prd.infoCmpny.telno;
              }
              const content =
                `<div class="overlaybox">` +
                `    <div class="boxtitle">${prd.infoCmpny.cmpnyNm}</div>` +
                '    <div class="">' +
                "    </div>" +
                "    <ul>" +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${prd.infoCmpny.adres}</span>` +
                '        </li>' +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${phone}</span>` +
                '        </li>' +
                "   </ul>" +
                '</div>';

              const customOverlay = new window.kakao.maps.CustomOverlay({
                position: markerPosition,
                content: content,
                xAnchor: 0.5,
                yAnchor: 1.55,
              });

              const markerImage = new kakao.maps.MarkerImage(
                "/newMarker2.png",
                new kakao.maps.Size(30, 40),
                {
                  spriteOrigin: new kakao.maps.Point(0, 0),
                  spriteSize: new kakao.maps.Size(30, 40),
                }
              );

              const marker = new window.kakao.maps.Marker({
                position: new window.kakao.maps.LatLng(
                  result[0].y,
                  result[0].x
                ),
                image: markerImage,
              });
              marker.setMap(map);

              customOverlay.setMap(map);
            } else {
              const options = {
                center: new window.kakao.maps.LatLng('36.3487787978337', '127.396961564889'),
                level: 3,
              };
              const map = new window.kakao.maps.Map(container, options);
              const markerPosition = new window.kakao.maps.LatLng(
                '36.3487787978337', '127.396961564889'
              );
              let phone = "";
              if (!prd.infoCmpny.telno) {
                phone = "번호없음";
              } else {
                phone = prd.infoCmpny.telno;
              }
              const content =
                `<div class="overlaybox">` +
                `    <div class="boxtitle">${prd.infoCmpny.cmpnyNm}(지도 표시불가 사업장)</div>` +
                '    <div class="">' +
                "    </div>" +
                "    <ul>" +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${prd.infoCmpny.adres}</span>` +
                '        </li>' +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${phone}</span>` +
                '        </li>' +
                "   </ul>" +
                '</div>';

              const customOverlay = new window.kakao.maps.CustomOverlay({
                position: markerPosition,
                content: content,
                xAnchor: 0.5,
                yAnchor: 1.55,
              });

              const marker = new window.kakao.maps.Marker({
                position: new window.kakao.maps.LatLng(
                  '36.3487787978337', '127.396961564889'
                ),
              });
              marker.setMap(map);

              customOverlay.setMap(map);
            }
          }
        );
      });
    };
    document.head.appendChild(script);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div id="map" style={{ width: "100%", height: "400px" }}></div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductMap;
