import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './styles/ProductDetailImage.css';

function ProductDetailImage({ prdDetail }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imgList, setImgList] = useState([]);

  // const images = [
  //   "https://dummyimage.com/800x600/007bff/ffffff",
  //   "https://dummyimage.com/800x600/28a745/ffffff",
  //   "https://dummyimage.com/800x600/dc3545/ffffff",
  //   "https://dummyimage.com/800x600/ffc107/ffffff",
  // ];

  useEffect(() => {
    if (prdDetail) {
      // prdDetail에서 이미지 정보 추출
      // const images = prdDetail.map((prd) => prd.repImg);
      const images = [];
      images.push(
        `https://cloud.1472.ai:18443/images/cloud/uploads/${prdDetail.repImg}`
      );
      images.push(prdDetail.extrlImgUrl);
      console.log(images);
      setImgList(images);
    }
  }, [prdDetail]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCurrentImageIndex(next);
    },
  };

  return (
    <div className="product-detail-image-container">
      <Slider {...settings}>
        {imgList.map((image, index) => (
          <div key={index}>
            <Image
              key={currentImageIndex}
              src={image}
              fluid
              className="product-detail-main-image"
            />
          </div>
        ))}
      </Slider>
      <div className="product-detail-images">
        {imgList.map((image, index) => (
          <img
            key={index}
            src={image}
            className={`product-detail-image ${
              currentImageIndex === index ? "selected" : ""
            }`}
            alt={`상품 이미지 ${index + 1}`}
            onClick={() => {
              setCurrentImageIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductDetailImage;
