import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import Loading from "./common/Loading";

function FeaturedProducts({ productList }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState([true, true, true, true]);

  const handleImageLoad = (index) => {
    setIsLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  return (
    <section className="py-5">
      <h2 className="text-center mb-5">인기 상품</h2>
      <Row>
        {productList.slice(0, 20).map((product, index) => (
          <Col key={index} xs={6} md={3} className="mb-5">
            <Card onClick={() => handleClick(product)} style={{ height: "80%", border: 0 }}>
              {isLoading[index] && (
                <div
                  style={{
                    height: "150px",
                  }}
                >
                  <Loading />
                </div>
              )}
              <Card.Img
                onLoad={() => handleImageLoad(index)}
                style={{
                  objectFit: "contain",
                  display: isLoading[index] ? "none" : "block",
                  height: "90%"
                }}
                src={
                  product?.prdImgFlpth
                    ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                    : product?.extrlImgUrl
                }
              />
              <Card.Body style={{ textAlign: "left", padding: 0 }}>
                <Card.Text style={{ fontSize: "12px", color: "rgb(153, 153, 153)" }}>
                  {/* {product?.infoCmpny.cmpnyNm} */}
                  <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{product?.infoCmpny.cmpnyNm}</span>
                </Card.Text>
                <Card.Title style={{ fontSize: "15px" }}>
                  {product?.prductNm.length > 12
                    ? product?.prductNm.substring(0, 12) + ".."
                    : product?.prductNm || ""}
                </Card.Title>
                <Card.Text style={{ fontSize: "18px", fontWeight: 700 }}>
                  {(product?.klightingPdCmPrice ?? product?.cnsmrPc).toLocaleString()}원
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <Card.Footer style={{ textAlign: "left", padding: "10px 0", border: 0 }}>
              <Card.Text style={{ fontSize: "12px", color: "rgb(153, 153, 153)" }}>
                {product?.infoCmpny.cmpnyNm}
              </Card.Text>
              <Card.Title style={{ fontSize: "15px" }}>
                {product?.prductNm.length > 12
                  ? product?.prductNm.substring(0, 12) + ".."
                  : product?.prductNm || ""}
              </Card.Title>
              <Card.Text style={{ fontSize: "12px" }}>
                {product?.pdCmPrice.toLocaleString()}원
              </Card.Text>
            </Card.Footer> */}
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default FeaturedProducts;
