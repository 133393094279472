import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import ProductDetail from "./components/product/ProductDetail";
import Login from "./components/member/Login";
import MyPage from "./components/member/Mypage";
import Cart from "./components/buy/Cart";
import Order from "./components/buy/Order";
import Footer from "./components/Footer";
import MobileNavbar from "./components/MobileNavbar";
import CategoryScreen from "./components/category/Categories";
import SearchResults from "./components/product/SearchResults";
import { Toaster } from 'react-hot-toast';
import "./App.css";
import Success from "./components/buy/Success";
import Fail from "./components/buy/Fail";
import OrderDetail from "./components/buy/OrderDetail";
import ScrollToTop from "./ScrollTop";
import MypageSearchResults from "./components/member/MypageSearchResults";
import Board from "./components/category/Board";;

function App() {
    return (
        <div
            className="App"
            style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
            <Router>
                <ScrollToTop />
                <div style={{ flex: "1 0 auto" }}>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/product-detail" element={<ProductDetail />} />
                        <Route path="/search-result" element={<SearchResults />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/my-page" element={<MyPage />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/order" element={<Order />} />
                        <Route path="/categories" element={<CategoryScreen />} />
                        <Route path="/success" element={<Success />} />
                        <Route path="/fail" element={<Fail />} />
                        <Route path="order-detail" element={<OrderDetail />} />
                        <Route path="/mypage-search-result" element={<MypageSearchResults />} />
                        <Route path="/news" element={<Board />} />
                    </Routes>
                    <Toaster />
                    <MobileNavbar />
                </div>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
