import React, { useEffect, useState } from 'react';
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";

const BoardRecomment = ({ recomment }) => {

  const [commentList, setCommentList] = useState([]);
  const [paged, setPaged] = useState(true);
  const [isCheck, setIscheck] = useState(false);

  useEffect(() => {
    getRecomment(paged);
    console.log("?")
  }, [recomment])

  // 댓글 조회
  const getRecomment = async (paged) => {
    setPaged(paged);
    let feedNo = 0;
    if (recomment.length != 0) {
      feedNo = recomment[0].feedNo;
      try {
        fetch("https://cloud.1472.ai:18443/api/v2/FeedMgmt/feedRecomment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            feedNo: feedNo,
            offset: 0,
            pageNumber: 0,
            pageSize: 3,
            paged: paged
          })
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setCommentList(data.content);
          })
          .catch((error) =>
            console.error(error)
          )
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderTime = (tiem) => {
    const currentTime = new Date();
    const givenTime = new Date(tiem);
    const timeDiff = currentTime - givenTime;
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));

    let timeAgo;
    if (minutes < 60) {
      timeAgo = `${minutes}분 전`;
    } else if (hours < 24) {
      timeAgo = `${hours}시간 전`;
    } else if (days < 7) {
      timeAgo = `${days}일 전`;
    } else {
      timeAgo = `${weeks}주 전`;
    }
    if (minutes === 0) {
      timeAgo = "방금"
    }
    return timeAgo;
  }

  return (
    <div>
      {
        commentList.map((item) => (
          <div key={item.feedRecommentNo}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoPersonCircleOutline size={40} />
              <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>
                <span style={{ fontWeight: "bold" }}>{item.mberNm}</span>
                <span style={{ margin: 0, }} >{item.content}</span>
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <span style={{ fontSize: "0.8rem" }}>{renderTime(item.createDt)}</span>
              {/* <span style={{ fontSize: "0.8rem" }}>{item.createDt}</span> */}
            </div>
          </div>
        ))
      }
      {
        recomment.length > 3 ? (
          <div onClick={() => {
            getRecomment(isCheck);
            setIscheck(!isCheck);
          }}
            style={{ cursor: "pointer" }}
          >{isCheck ? <IoIosArrowUp size={25} /> : "더보기 ..."}</div>) : null
      }
    </div>
  );
};

export default BoardRecomment;