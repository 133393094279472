import { useEffect, useState } from "react";
import "./Map.css";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

function Map({ productList, updateProductList, query }) {
  const [isOpen, setIsOpen] = useState(false);
  const [btn, setBtn] = useState("");

  const onClickSearchBarOpen = () => {
    setIsOpen(!isOpen);
  };

  const getProrudct = async (cmpnyNo) => {
    try {
      const tempJson = {
        cmpnyNo: cmpnyNo,
        prductNm: query,
        klightingYn: 'Y',
        offset: 0,
        pageNumber: 0,
        pageSize: 100,
        paged: true,
      }
      const response = await fetch(
        "https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tempJson),
        }
      );

      const prdList = await response.json();
      const dummy = prdList.content;
      const newPrdListPromises = dummy.map((item) => getCmpnyNm(item));
      const newPrdList = await Promise.all(newPrdListPromises);
      updateProductList(newPrdList);
    } catch (error) {
      console.error(error);
    }
  }

  const getCmpnyNm = async (item) => {
    const url = `https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${item.cmpnyNo}`;

    return fetch(url, { method: "POST", headers: { accept: "*/*" } })
      .then((response) => response.json())
      .then((data) => {
        const infoCmpny = data;
        return { ...item, infoCmpny };
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  let list = [];
  productList.forEach((item) => {
    const index = list.findIndex((obj) => obj.cmpnyNo === item.cmpnyNo);
    if (index === -1) {
      list.push({ cmpnyNo: item.cmpnyNo, adres: item.infoCmpny.adres, cmpnyNm: item.infoCmpny.cmpnyNm, telno: item.infoCmpny.telno });
    }
  });

  function createBtn() {
    return (
      <AiOutlineClose size="28"
        onClick={onClickSearchBarOpen}
        type="button"
        style={{ display: !query ? "none" : "block", position: "absolute", right: "15px" }} />
    );
  }

  useEffect(() => {
    const script = document.createElement("script");
    const SERVER_APP_KEY = "9c5596b3875cec638b4d2175ab09a688";
    const LOCAL_APP_KEY = "8400068f9c2268cfa77c552875a9ac80";
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${SERVER_APP_KEY}&autoload=false&libraries=services`;
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load(function () {
        let markers = [];
        let infoMarkers = [];
        let infoList = [];
        let ascPlaces = []
        const { kakao } = window;
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude);
          console.log(longitude);
          const mapContainer = document.getElementById('map'), // 지도를 표시할 div 
            mapOption = {
              center: new kakao.maps.LatLng(latitude, longitude), // 지도의 중심좌표
              level: 3 // 지도의 확대 레벨
            };
          const map = new kakao.maps.Map(mapContainer, mapOption);
          const zoomControl = new kakao.maps.ZoomControl();
          map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

          const latlng = new kakao.maps.LatLng(latitude, longitude);
          const markerImage = new kakao.maps.MarkerImage(
            "/newMan2.png",
            new kakao.maps.Size(30, 40),
            {
              spriteOrigin: new kakao.maps.Point(0, 0),
              spriteSize: new kakao.maps.Size(30, 40),
            }
          );

          const marker = new kakao.maps.Marker({
            position: latlng,
            map: map,
            image: markerImage,
          });

          const content =
            '<div class ="label" style="background-color:#2b2d36; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); margin-top: 30px; border-radius: 5px;"><span class="left"></span><span class="center" style="color: #eee;">현재위치</span><span class="right"></span></div>';

          const customOverlay = new kakao.maps.CustomOverlay({
            position: new kakao.maps.LatLng(latitude, longitude),
            content: content,
          });

          customOverlay.setMap(map);

          const geocoder = new window.kakao.maps.services.Geocoder();
          list.forEach((item) => {
            geocoder.addressSearch(item.adres, (result, status) => {
              if (status === window.kakao.maps.services.Status.OK) {
                const markerImage = new kakao.maps.MarkerImage(
                  "/newMarker2.png",
                  new kakao.maps.Size(30, 40),
                  {
                    spriteOrigin: new kakao.maps.Point(0, 0),
                    spriteSize: new kakao.maps.Size(30, 40),
                  }
                );
                const marker = new window.kakao.maps.Marker({
                  position: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                  map: map,
                  image: markerImage,
                });

                let telno = "";
                if (!item.telno) {
                  telno = "번호없음"
                } else {
                  telno = item.telno;
                }
                const content =
                  `<div class="overlaybox">` +
                  `    <div class="boxtitle">${item.cmpnyNm}</div>` +
                  '    <div class="">' +
                  "    </div>" +
                  "    <ul>" +
                  '        <li>' +
                  '            <span class="number"></span>' +
                  `            <span class="title">${item.adres}</span>` +
                  '        </li>' +
                  '        <li>' +
                  '            <span class="number"></span>' +
                  `            <span class="title">${telno}</span>` +
                  '        </li>' +
                  "   </ul>" +
                  '</div>';
                const customOverlay = new window.kakao.maps.CustomOverlay({
                  position: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                  content: content,
                  xAnchor: 0.5,
                  yAnchor: 1.55,
                });

                const content2 =
                  `<div class ="label" style="background-color:#2b2d36; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); margin-top: 30px; border-radius: 5px;"><span class="left"></span><span class="center" style="color: #eee;">${item.cmpnyNm}</span><span class="right"></span></div>`;
                const customOverlay2 = new window.kakao.maps.CustomOverlay({
                  position: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                  content: content2,
                  xAnchor: 0.5,
                  yAnchor: 0.5,
                })
                // customOverlay.setMap(map);
                customOverlay2.setMap(map);
              } else {
                console.log(item.adres);
                console.error("Failed to search address");
              }
            })
          })

          const polyline = new kakao.maps.Polyline();

          // id 로 searchForm searchPlaces함수 실행
          const searchForm = document.getElementById("submit_btn");
          searchForm?.addEventListener("click", function (e) {
            e.preventDefault();
            searchPlaces();
          });

          if (!query) {
            setIsOpen(true);
          } else {
            searchPlaces();
          }

          // 키워드 검색을 요청하는 함수입니다
          function searchPlaces() {
            const geocoder = new window.kakao.maps.services.Geocoder();
            const addressSearchPromises = list.map((item) => {
              return new Promise((resolve) => {
                geocoder.addressSearch(item.adres, (result, status) => {
                  if (status === window.kakao.maps.services.Status.OK) {
                    resolve({
                      cmpnyNo: item.cmpnyNo,
                      address_name: item.adres,
                      category_group_code: "",
                      category_group_name: "",
                      category_name: "",
                      distance: "",
                      id: "",
                      phone: item.telno,
                      place_name: item.cmpnyNm,
                      place_url: "",
                      road_address_name: "",
                      x: result[0].x,
                      y: result[0].y
                    });
                  } else {
                    resolve(null); // 오류 발생 시 null로 처리
                  }
                });
              });
            });

            Promise.all(addressSearchPromises)
              .then((results) => {
                const infoList = results.filter((result) => result !== null); // null인 결과 필터링
                placesSearchCB(infoList);
              })
              .catch((error) => {
                console.error(error);
              });

            if (!query.replace(/^\s+|\s+$/g, '')) {
              alert('키워드를 입력해주세요!');
              return false;
            }
          }

          // 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
          function placesSearchCB(data) {
            // 정상적으로 검색이 완료됐으면
            // 검색 목록과 마커를 표출합니다
            displayPlaces(data);

            // 페이지 번호를 표출합니다
            // displayPagination(pagination);
          }

          // 검색 결과 목록과 마커를 표출하는 함수입니다
          function displayPlaces(places) {
            console.log(places);

            let listEl = document.getElementById('placesList'),
              menuEl = document.getElementById('menu_wrap'),
              fragment = document.createDocumentFragment(),
              listStr = '';

            // 검색 결과 목록에 추가된 항목들을 제거합니다
            removeAllChildNods(listEl);

            // 지도에 표시되고 있는 마커를 제거합니다
            removeMarker();


            // 거리순 정렬
            for (let i = 0; i < places.length; i++) {
              const newPath = [
                new kakao.maps.LatLng(latitude, longitude),
                new kakao.maps.LatLng(places[i].y, places[i].x)
              ];
              polyline.setPath(newPath);
              const length = polyline.getLength();
              const placeWithLength = { ... { ...places[i], length: length } };
              ascPlaces.push(placeWithLength);
            }
            ascPlaces.sort((a, b) => a.length - b.length);

            for (let i = 0; i < ascPlaces.length; i++) {
              // 마커를 생성하고 지도에 표시합니다
              const placePosition = new kakao.maps.LatLng(ascPlaces[i].y, ascPlaces[i].x),
                marker = addMarker(placePosition, i),
                itemEl = getListItem(i, ascPlaces[i]); // 검색 결과 항목 Element를 생성합니다

              (function (marker, ascPlaces) {

                kakao.maps.event.addListener(marker, "click", function () {
                  displayInfowindow(marker, ascPlaces, placePosition);
                  moveDisplay(placePosition);
                  getProrudct(ascPlaces.cmpnyNo);
                })

                itemEl.addEventListener("click", function () {
                  displayInfowindow(marker, ascPlaces, placePosition);
                  moveDisplay(placePosition);
                  getProrudct(ascPlaces.cmpnyNo);
                })

              })(marker, ascPlaces[i]);

              fragment.appendChild(itemEl);
            }

            // 검색결과 항목들을 검색결과 목록 Element에 추가합니다
            listEl.appendChild(fragment);
            menuEl.scrollTop = 0;
          }

          // 검색결과 항목을 Element로 반환하는 함수입니다
          function getListItem(index, ascPlaces) {

            let distance;
            if (ascPlaces.length >= 1000) {
              const distanceKm = (ascPlaces.length / 1000).toFixed(2);
              distance = distanceKm + 'km';
            } else {
              const distancM = Math.floor(ascPlaces.length);
              distance = distancM + 'm';
            }
            let phone = "";
            if (!ascPlaces.phone) {
              phone = "번호없음";
            } else {
              phone = ascPlaces.phone;
            }

            let el = document.createElement('li');
            let itemStr = '<span class="markerbg marker_' + (index + 1) + '"></span>' + '<div class="info">' + '<h5>' + ascPlaces.place_name + '</h5>';
            if (ascPlaces.road_address_name) {
              itemStr += `<span> ${ascPlaces.road_address_name} </span><span class="jibun gray"> ${ascPlaces.address_name} </span>`;
            } else {
              itemStr += `<span> ${ascPlaces.address_name} </span>`;
            }
            itemStr += `<span class="tel"> ${phone} </span></div>`;
            itemStr += `<span class="km"> ${distance} </span></div>`;
            el.innerHTML = itemStr;
            el.className = 'item';

            setBtn("test");

            return el;
          }

          // 마커를 생성하고 지도 위에 마커를 표시하는 함수입니다
          function addMarker(position, idx, title) {
            const markerImage = new kakao.maps.MarkerImage(
              "/newMarker2.png",
              new kakao.maps.Size(30, 40),
              {
                spriteOrigin: new kakao.maps.Point(0, 0),
                spriteSize: new kakao.maps.Size(30, 40),
              }
            );

            const marker = new kakao.maps.Marker({
              position: position, // 마커의 위치
              image: markerImage,
            });

            marker.setMap(map); // 지도 위에 마커를 표출합니다
            markers.push(marker);  // 배열에 생성된 마커를 추가합니다

            return marker;
          }

          // 지도 위에 표시되고 있는 마커를 모두 제거합니다
          function removeMarker() {
            for (var i = 0; i < markers.length; i++) {
              markers[i].setMap(null);
            }
            markers = [];
          }

          function removeInfo() {
            for (let i = 0; i < infoMarkers.length; i++) {
              infoMarkers[i].setMap(null);
            }
          }

          // 검색결과 목록 또는 마커를 클릭했을 때 호출되는 함수입니다
          // 인포윈도우에 장소명을 표시합니다
          function displayInfowindow(marker, places, placePosition) {

            let phone = "";
            if (!ascPlaces.phone) {
              phone = "번호없음";
            } else {
              phone = places.phone;
            }

            const content =
              `<div class="overlaybox">` +
              `    <div class="boxtitle">` + places.place_name + `</div>` +
              '    <div class="">' +
              "    </div>" +
              "    <ul>" +
              '        <li>' +
              '            <span class="number"></span>' +
              `            <span class="title">` + places.address_name + `</span>` +
              '        </li>' +
              '        <li>' +
              '            <span class="number"></span>' +
              `            <span class="title">` + phone + `</span>` +
              '        </li>' +
              "   </ul>" +
              '</div>';

            const customOverlay = new window.kakao.maps.CustomOverlay({
              position: placePosition,
              content: content,
              xAnchor: 0.5,
              yAnchor: 1.55,
            });
            infoMarkers.push(customOverlay);


            removeInfo();
            customOverlay.setMap(map);
          }

          // 화면이동
          function moveDisplay(placePosition) {
            const bounds = new kakao.maps.LatLngBounds();
            const point = [
              new kakao.maps.LatLng(latitude, longitude),
              new kakao.maps.LatLng(placePosition.Ma, placePosition.La)
            ]
            for (let x = 0; x < point.length; x++) {
              bounds.extend(point[x]);
            }
            map.setBounds(bounds);
          }

          // 검색결과 목록의 자식 Element를 제거하는 함수입니다
          function removeAllChildNods(el) {
            while (el.hasChildNodes()) {
              el.removeChild(el.lastChild);
            }
          }
        })

      })
    }
  }, [])

  return (
    <div style={{ width: isOpen ? "100% - 300px" : "100%", height: "400px", position: "relative" }}>
      <div id="map" style={{ position: "absolute", width: "100%", height: "400px" }} />
      <div id="menuDiv" style={{ display: "flex", position: "absolute", zIndex: "3", opacity: "0.8" }}>
        <div id="menu_wrap" style={{ width: "300px", height: "400px", display: isOpen ? "none" : "block" }}>
          <ul id="placesList"></ul>
          <div id="pagination"></div>
        </div>
        <div id="btnDiv" style={{ justifyContent: "start" }}>
          <div id="btnOn">
            {btn ? (
              isOpen ? (
                <div style={{ backgroundColor: "white", opacity: "0.9", margin: "3px", padding: "3px", border: "1px solid black" }}>
                  <AiOutlineMenu size="22"
                    onClick={onClickSearchBarOpen}
                    type="button"
                    style={{ display: !query ? "none" : "block" }} />
                </div>) : (createBtn())
            ) : (
              null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
