import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
  Button,
  ListGroup,
} from "react-bootstrap";
import Modal from "../common/Modal";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";
import { toast } from "react-hot-toast";
import { loadTossPayments } from '@tosspayments/payment-sdk'
import dayjs from 'dayjs'
import "./order.css";

const Order = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [items, setItems] = useState([]);
  const [ord, setOrd] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [istPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isDeleteAddress, setIsDeleteAddress] = useState(false);
  const [checkDel, setCheckDel] = useState("");
  const [address, setAddress] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [formData, setFormData] = useState([]);
  const [resultAddress, setResultAddress] = useState([]);
  // const [dtlList, setDtlList] = useState([]);
  const location = useLocation();

  const mberNm = JSON.parse(sessionStorage.getItem("user")).name;
  const mberNo = JSON.parse(sessionStorage.getItem('user')).mberNo;
  const tel = JSON.parse(sessionStorage.getItem("user")).moblphonNo;
  const email = JSON.parse(sessionStorage.getItem("user")).email;
  const cmpnyNo = JSON.parse(sessionStorage.getItem('user')).cmpnyNo;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      handleGetOrd();
    }
  }, [mberNm]);

  const handleGetOrd = async () => {
    let test = [];
    try {
      await Promise.all(location.state.orderNum.map(async (item) => {
        const response = await fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordNo: item,
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true
          })
        });
        const data = await response.json();
        const dummy = data.content;
        const newList = await Promise.all(dummy);

        newList.map((item) => {
          setItems((list) => [...list, item])
          test.push(item);
        })

        const response2 = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordNo: item,
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true
          })
        })
        const data2 = await response2.json();
        console.log(data2);
        setOrd((list) => [...list, data2.content[0]]);
      }))
    } catch (error) {
      console.error(error);
    }
    // try {
    //   const response = await fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       ordNo: location.state.orderNum,
    //       offset: 0,
    //       pageNumber: 0,
    //       pageSize: 10,
    //       paged: true
    //     })
    //   });
    //   const data = await response.json();
    //   const dummy = data.content;
    //   const newList = await Promise.all(dummy);
    //   setItems(newList);

    //   newList.map((item) => {
    //     const ordDtlNo = item.ordDtlNo;
    //     const ordNo = item.ordNo;
    //     const newData = {
    //       ordDtlNo: ordDtlNo,
    //       ordNo: ordNo,
    //       prdNo: item.prdNo,
    //       prductNm: item.prductNm,
    //       setNo: 0,
    //       totAmount: item.totAmount,
    //       prductCl: "",
    //       qy: item.qy,
    //       cost: item.cost,
    //       m: 0,
    //       n: 0,
    //       supplyAmt: 0,
    //       tax: 0,
    //       ordQy: 0,
    //       bomSetSe: "1",
    //       asPd: 0,
    //       prmpc: 0,
    //       prmpcSm: 0,
    //       profitAmt: 0,
    //       selngEntrpsNo: 0,
    //       socNo: 0,
    //       dlivMatter: "",
    //       vendnm: "",
    //       delYn: "N",
    //       repImg: item.repImg,
    //       selngDataNo: 0,
    //       dlvPrpNo: "",
    //       ordReqNo: 0,
    //       setYn: "N"
    //     };
    //     setDtlList((list) => [...list, newData])
    //   });

    //   const response2 = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       ordNo: newList[0].ordNo,
    //       offset: 0,
    //       pageNumber: 0,
    //       pageSize: 10,
    //       paged: true
    //     })
    //   });
    //   const data2 = await response2.json();
    //   console.log(data2);
    //   setOrd(data2.content);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleCheckboxChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCloseModal = (cart) => {
    setIsModalOpen(false);
  };

  const handleClosePostModal = () => {
    setIsPostModalOpen(false);
  }

  const handleCloseAddressModal = () => {
    setIsAddressModalOpen(false);
  }

  const handleCloseDeleteAddress = () => {
    setIsDeleteAddress(false);
  }

  // 주소 검색시 실행
  const handleComplete = (data) => {
    console.log(data)
    // 결과저장
    setResultAddress([
      ...resultAddress,
      { address: data.roadAddress, zonecode: data.zonecode, jibunAddress: data.jibunAddress }
    ]);
    // Input 결과저장
    setAddress(data.roadAddress)
    setIsPostModalOpen(false)
  };

  // 주소 저장시 실행
  const saveAddress = (event) => {
    event.preventDefault();
    const validPhNum = phoneRegExp.test(formData.telNum);
    if (!validPhNum) {
      errorToast("올바른 핸드폰 번호를 입력하세요.")
      return;
    }
    if (!document.querySelector('input[name="address"]').value) {
      errorToast("배송지를 확인하세요.")
      return;
    }
    // 받는사람 정보 input 정보 입력
    document.querySelector('input[name="name2"]').value = formData.name;
    document.querySelector('input[name="telNum2"]').value = formData.telNum;
    document.querySelector('input[name="delyName2"]').value = formData.delyName;
    document.querySelector('input[name="lastAddress"]').value = `${resultAddress[0].address} ${formData.detailAddress}`;
    // 배송지 저장 api
    fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberDlvySpt", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mberNo: mberNo,
        // regCmpnyNo: cmpnyNo,
        dlvySptNm: formData.delyName,
        zip: resultAddress[0].zonecode,
        roadAdres: resultAddress[0].address,
        lnmAdres: resultAddress[0].jibunAddress,
        dtlAdres: formData.detailAddress,
        receiveName: formData.name,
        receivePhone: formData.telNum,
        useYn: "Y"
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        successToast("배송지가 추가되었습니다.")
      })
      .catch((error) => console.error(error))
    setAddress("")
    handleCloseModal()
  }

  // 배송지 삭제
  const deleteAddress = (dlvySptNo) => {
    fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberDlvySpt", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dlvySptNo: dlvySptNo,
        mberNo: mberNo,
        useYn: "N"
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setIsAddressModalOpen(false)
        setCheckDel(false);
        successToast("배송지가 삭제되었습니다.")
      })
      .catch((error) => console.error(error))
  }

  const openAddressModla = () => {
    // 배송지 조회 api
    fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberDlvySpt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mberNo: mberNo,
        useYn: "Y",
        offset: 0,
        pageNumber: 0,
        pageSize: 5,
        paged: true
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        const dummy = data.content
        if (0 == dummy.length) {
          errorToast("저장된 배송지가 없습니다.")
          return;
        }
        setAddressList(dummy.map((item) => item));
        setIsAddressModalOpen(true)
      })
      .catch((error) => console.error(error))
  }

  // 배송지 추가 input 감지
  const handleFieldBlur = (e) => {
    const fieldName = e.target.name;
    const newValue = e.target.value;
    const newFormData = { ...formData, [fieldName]: newValue };
    setFormData(newFormData);
  }

  // 배송지 선택시 받는사람 정보 input 정보 입력
  const handleSelectAddress = (item) => {
    document.querySelector('input[name="name2"]').value = item.receiveName;
    document.querySelector('input[name="telNum2"]').value = item.receivePhone;
    document.querySelector('input[name="delyName2"]').value = item.dlvySptNm;
    document.querySelector('input[name="lastAddress"]').value = `${item.lnmAdres} ${item.dtlAdres}`;
    setIsAddressModalOpen(false)
  };

  let totalPrice = 0;
  totalPrice = items.reduce(
    (acc, item) => acc + item.totAmount,
    0
  );

  // 주문서 배송지 변경
  const updateOrdAddress = async (name, telNum, delyName, lastAddress) => {
    const nowTime = dayjs().format("YYYY-MM-DD HH:mm:ss")
    await Promise.all(location.state.orderNum.map(async (item) => {
      let newData = {};
      let dtlList = [];
      for (let i = 0; i < items.length; i++) {
        console.log(items[i])
        if (item == items[i].ordNo) {
          newData = {
            ordDtlNo: items[i].ordDtlNo,
            ordNo: items[i].ordNo,
            prdNo: items[i].prdNo,
            prductNm: items[i].prductNm,
            setNo: 0,
            totAmount: items[i].totAmount,
            prductCl: "",
            qy: items[i].qy,
            cost: items[i].cost,
            m: 0,
            n: 0,
            supplyAmt: 0,
            tax: 0,
            ordQy: 0,
            bomSetSe: "1",
            asPd: 0,
            prmpc: 0,
            prmpcSm: 0,
            profitAmt: 0,
            selngEntrpsNo: 0,
            socNo: 0,
            dlivMatter: "",
            vendnm: "",
            delYn: "N",
            repImg: items[i].repImg,
            selngDataNo: 0,
            dlvPrpNo: "",
            ordReqNo: 0,
            setYn: "N",
          }
          dtlList.push(newData);
        }
      }

      const ordPick = ord.filter((list) => list.ordNo == item);
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ord", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordVo: {
            dlvySptAdres: "",
            // dlvySptNm: '',
            dtlList: dtlList,
            ordNo: ordPick[0].ordNo,
            ordCmpnyNo: cmpnyNo, // 주문회사번호
            ordMberNo: 1, // 주문회원번호
            wrter: 1, // 작성자
            ordSttusCl: "ORDS02", // 주문상태분류
            progrsSttus: "OWS011", // 진행상태
            writeDt: nowTime, // 작성일시
            ordDt: nowTime, // 주문일시
            dedtDe: nowTime, // 납기일자
            bcncNo: ordPick[0].bcncNo, // 거래처번호
            bcncNm: ordPick[0].bcncNm, // 거래처명
            bcncTelno: ordPick[0].bcncTelno, // 거래처전화번호
            sptNm: lastAddress, // 현장명 넣기
            sptAdres: lastAddress, // 현장주소 넣기
            dlvyMth: "택배", // 배송방법
            dlvyCt: "선불", // 배송비
            rcpnt: name, // 수령자
            dept: "없음", // 부서
            vatCl: "VATS03", // 부가세분류
            demandTot: 0, // 출고액합계
            trnsprtDvyfgAm: 0, // 운송,납품액
            bndePrductCl: "전체", // 일괄제품분류
            bndeMargin: 0, // 일괄마진
            bndeNego: 0, // 일괄네고
            sumry: "1", // 적요
            ordCours: "OCR001", // 주문경로
            ordCrcmstncs: "1", // 주문경위
            buyNo: 0, // 매입관리번호
            selngNo: 0, // 매출관리번호
            faxYn: "N", // 팩스여부
            prtYn: "N", // 출력여부
            dlivyYn: "N", // 출고여부
            bilclctYn: "N", // 수금여부
            delYn: "N", // 삭제여부
            schRegYn: "N" // 일정등록여부
          }
        })
      })
      const data = await response.json();
      console.log(data);
    }))
  }

  // 구매하기
  const btnBuy = async () => {
    const name = document.querySelector('input[name="name2"]').value
    const telNum = document.querySelector('input[name="telNum2"]').value
    const delyName = document.querySelector('input[name="delyName2"]').value
    const lastAddress = document.querySelector('input[name="lastAddress"]').value

    if (!selectedOption) {
      errorToast("결재수단을 선택해 주세요.")
      return;
    } else if (!name) {
      errorToast("이름을 확인해 주세요.")
      return;
    } else if (!telNum) {
      errorToast("전화번호를 확인해 주세요.")
      return;
    } else if (!delyName) {
      errorToast("배송지명을 확인해 주세요.")
      return;
    } else if (!lastAddress) {
      errorToast("주소를 확인해 주세요.")
      return;
    }
    updateOrdAddress(name, telNum, delyName, lastAddress);


    // 결제 수단 확인
    let option = "";
    if ("0" === selectedOption) {
      option = "계좌이체"
    } else if ("1" === selectedOption) {
      option = "휴대폰"
    } else if ("2" === selectedOption) {
      option = "카드"
    } else {
      option = "가상계좌"
    }

    let orderName = "";
    if (items.length === 1) {
      orderName = items[0].prductNm
    } else {
      orderName = items[0].prductNm + " 외 " + (items.length - 1) + "건";
    }
    const orderId = dayjs().format("YYYYMMDD") + "-" + location.state.orderNum[0];

    const CLIENT_KEY = 'test_ck_BE92LAa5PVbp6aD2Bye87YmpXyJj';
    const SERVER_URL = 'https://e-changgo.com';
    const LOCAL_URL = 'http://localhost:3000';

    const tossPayments = await loadTossPayments(CLIENT_KEY);

    tossPayments.requestPayment(option, { // 결제수단 파라미터
      // 결제 정보 파라미터
      amount: totalPrice + 2500,
      orderId: orderId,
      orderName: orderName,
      customerName: mberNm,
      successUrl: `${SERVER_URL}/success`,
      failUrl: `${SERVER_URL}/fail`,
    })
      .then(function (data) {
        console.log(data)
      })
      .catch(function (error) {
        if (error.code === 'USER_CANCEL') {
          // 결제 고객이 결제창을 닫았을 때 에러 처리
        } else if (error.code === 'INVALID_CARD_COMPANY') {
          // 유효하지 않은 카드 코드에 대한 에러 처리
        }
      })
  }

  const errorToast = (message) => {
    toast.error(message, {
      position: "top-center",
      duration: 2000,
      style: {
        marginTop: "50px",
        background: "#ff4040",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "bold",
      },
    });
  }

  const successToast = (message) => {
    toast.success(message, {
      position: "top-right",
      duration: 2000,
      style: {
        marginTop: "100px",
        background: "#F39802",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "bold",
      },
      iconTheme: {
        primary: 'white',
        secondary: 'black',
      },
    });
  }

  const handleClick = async (item) => {
    const prdNo = item.prdNo.split("_")[0];
    const bcncCnNo = ord.filter((list) => list.ordNo == item.ordNo);
    let infoCmpny = "";
    let pdStock = "";
    try {
      const response = await fetch(`https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${bcncCnNo[0].bcncNo}`, {
        method: "POST",
        headers: {
          accept: "*/*"
        }
      });
      const data = await response.json();
      infoCmpny = data;

      const response2 = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          cmpnyNo: infoCmpny.cmpnyNo,
          klightingYn: 'Y',
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      })
      const data2 = await response2.json();
      pdStock = data2.content[0];
    } catch (error) {
      console.error(error);
    }
    const product = {
      brand: pdStock.brand,
      cmpnyNo: pdStock.cmpnyNo,
      infoCmpny: infoCmpny,
      pdCmPrice: item.cost,
      prdImgFlpth: item.repImg,
      prdNo: prdNo,
      prductNm: item.prductNm,
    }
    navigate("/product-detail", {
      state: { product: product }
    })
  }

  // 번호 유효성
  const phoneRegExp = /^(01[0]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

  return (
    <Container
      className="py-5"
      style={{ marginTop: "5em", marginBottom: "5em" }}
    >
      <h2 className="text-left">주문/결제</h2>
      <hr />
      {/* 구매자 정보 */}
      <Row className="mb-5 mt-5">
        <Col>
          <h4 className="text-left">구매자 정보</h4>
          <Card>
            <Card.Body>
              <Form className="text-left">
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label>이름</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="이름을 입력하세요"
                    value={mberNm}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="phone" className="mb-3">
                  <Form.Label>전화번호</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="전화번호를 입력하세요"
                    value={tel}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>이메일</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="이메일을 입력하세요"
                    value={email}
                    readOnly
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* 받는 사람 정보 */}
      <Row className="mb-5">
        <Col>
          <div className="d-flex align-items-center">
            <h4 className="text-left">받는사람정보</h4>
            <Button
              variant="primary"
              size="sm"
              block="true"
              style={{ backgroundColor: "#F39802", border: 0, marginLeft: "1.5em" }}
              onClick={() => {
                openAddressModla()
              }}
            >
              배송지 변경
            </Button>
            <Button
              variant="primary"
              size="sm"
              block="true"
              style={{ backgroundColor: "#F39802", border: 0, marginLeft: "1.5em" }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              배송지 추가
            </Button>
          </div>

          <Card>
            <Card.Body>
              <Form className="text-left">
                <Form.Group controlId="receiverName" className="mb-3">
                  <Form.Label>이름</Form.Label>
                  <Form.Control
                    type="text"
                    name="name2"
                    placeholder="이름을 입력하세요"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="receiverPhone" className="mb-3">
                  <Form.Label>전화번호</Form.Label>
                  <Form.Control
                    type="text"
                    name="telNum2"
                    placeholder="전화번호를 입력하세요"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="receiverDelyName" className="mb-3">
                  <Form.Label>배송지명</Form.Label>
                  <Form.Control
                    type="text"
                    name="delyName2"
                    placeholder="배송지 이름을 입력하세요"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="receiverAddress" className="mb-3">
                  <Form.Label>배송지 주소</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastAddress"
                    placeholder="배송지 주소를 입력하세요"
                    readOnly
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* 상품 정보 */}
      <Row className="mb-5">
        <Col>
          <h4 className="text-left">상품정보</h4>
          <Card style={{ border: 0 }}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>이미지</th>
                  <th style={{ width: "60%" }}>상품명</th>
                  <th style={{ whiteSpace: "nowrap" }}>수량</th>
                  <th style={{ whiteSpace: "nowrap" }}>상품금액</th>
                </tr>
              </thead>
              <tbody>
                {
                  items.map((item) => (
                    <tr key={item.prdNo}>
                      <td><img onClick={() => {
                        handleClick(item);
                      }} style={{ width: 100, cursor: "pointer" }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.repImg}`} /></td>
                      <td style={{ cursor: "pointer" }} onClick={() => {
                        handleClick(item);
                      }} className="text-center">{item.prductNm}</td>
                      <td>{item.qy}</td>
                      <td className="text-center">{item.totAmount.toLocaleString()}원</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      {/* 주문 요약 */}
      <Row className="mb-5">
        <Col>
          <h4 className="text-left">결제정보</h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1}>총 상품금액</td>
                    <td colSpan={7} className="text-right">
                      {(totalPrice + 0).toLocaleString()}원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>배송비</td>
                    <td colSpan={7} className="text-right">
                      2,500원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>총 결제금액</td>
                    <td colSpan={7} className="text-right">
                      {(totalPrice + 2500).toLocaleString()}원
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8}>
                      <h5 style={{ marginTop: "1em" }}>결제방법</h5>
                    </td>
                  </tr>
                  {/* 모바일 해상도에서는 셀렉트박스를 사용 */}
                  <tr className="d-md-none">
                    <td colSpan={8}>
                      <Form.Select
                        aria-label="결제 방법 선택"
                        value={selectedOption}
                        onChange={handleCheckboxChange}
                        className="form-control form-control-sm"
                      >
                        <option value="">결제 방법 선택</option>
                        <option value="0">계좌이체</option>
                        <option value="1">휴대폰</option>
                        <option value="2">신용/체크카드</option>
                        <option value="3">무통장입금</option>
                      </Form.Select>
                    </td>
                  </tr>
                  {/* 데스크톱 해상도에서는 체크박스를 사용 */}
                  <tr className="d-none d-md-table-row">
                    <td colSpan={2}>
                      <Form.Check
                        type="checkbox"
                        label="계좌이체"
                        id="accTransfer"
                        htmlFor="accTransfer"
                        value="0"
                        style={{ marginRight: "1em" }}
                        checked={selectedOption === "0"}
                        onChange={handleCheckboxChange}
                      />
                    </td>
                    <td colSpan={2}>
                      <Form.Check
                        type="checkbox"
                        label="휴대폰"
                        id="creditPhone"
                        htmlFor="creditPhone"
                        value="1"
                        style={{ marginRight: "1em" }}
                        checked={selectedOption === "1"}
                        onChange={handleCheckboxChange}
                      />
                    </td>
                    <td colSpan={2}>
                      <Form.Check
                        type="checkbox"
                        label="신용/체크카드"
                        id="card"
                        htmlFor="card"
                        value="2"
                        style={{ marginRight: "1em" }}
                        checked={selectedOption === "2"}
                        onChange={handleCheckboxChange}
                      />
                    </td>
                    <td colSpan={2}>
                      <Form.Check
                        type="checkbox"
                        label="무통장입금"
                        id="bankbook"
                        htmlFor="bankbook"
                        value="3"
                        style={{ marginRight: "1em" }}
                        checked={selectedOption === "3"}
                        onChange={handleCheckboxChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* 배송지추가 모달 */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="배송지 추가">
        <Card>
          <Card.Body>
            <Form className="text-left" onSubmit={saveAddress}>
              <Form.Group controlId="receiverName" className="mb-3">
                <Form.Label>이름</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="이름을 입력하세요"
                  onBlur={handleFieldBlur}
                  required
                />
              </Form.Group>
              <Form.Group controlId="receiverPhone" className="mb-3">
                <Form.Label>전화번호</Form.Label>
                <Form.Control
                  type="text"
                  name="telNum"
                  placeholder="전화번호를 입력하세요"
                  onBlur={handleFieldBlur}
                  required
                />
              </Form.Group>
              <Form.Group controlId="receiverDelyName" className="mb-3">
                <Form.Label>배송지명</Form.Label>
                <Form.Control
                  type="text"
                  name="delyName"
                  placeholder="배송지 이름을 입력하세요"
                  onBlur={handleFieldBlur}
                  required
                />
              </Form.Group>
              <Form.Group controlId="receiverAddress" className="mb-3" style={{ display: "inline-block" }}>
                <Form.Label>배송지 주소</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="배송지 주소를 입력하세요"
                  readOnly
                  required
                  value={address}
                />
              </Form.Group>
              <Button
                style={{ backgroundColor: "#F39802", border: 0 }}
                onClick={() => {
                  setIsPostModalOpen(true)
                }}
              >주소검색
              </Button>
              <Form.Group controlId="detailAddress" className="mb-3">
                <Form.Label>상세주소</Form.Label>
                <Form.Control
                  type="text"
                  name="detailAddress"
                  placeholder="상세주소"
                  onBlur={handleFieldBlur}
                  required
                />
              </Form.Group>

              <Button
                style={{ backgroundColor: "#F39802", border: 0, float: "right" }}
                type="submit"
              >저장</Button>
            </Form>
          </Card.Body>
        </Card>
        <Button variant="light" className="mr-2" onClick={() => {
          setFormData("");
          setAddress("");
          handleCloseModal()
        }}
          style={{ position: "absolute", top: 0, right: 0 }}>
          X
        </Button>
      </Modal>

      {/* 배송지삭제 */}
      <Modal isOpen={isDeleteAddress} onClose={handleCloseDeleteAddress} title="배송지 삭제" name="common">
        <p>삭제하시겠습니까?</p>
        <Button
          variant="light"
          style={{ color: "#F39802", border: "1px solid #F39802" }}
          className="mr-2"
          onClick={() => {
            setCheckDel("");
            handleCloseDeleteAddress();
          }}
        >
          취소
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: "#F39802", border: 0 }}
          onClick={() => {
            deleteAddress(checkDel);
            setIsDeleteAddress(false);
          }}
        >
          확인
        </Button>
      </Modal>

      {/* 주소검색 */}
      <Modal isOpen={istPostModalOpen} onClose={handleClosePostModal} title="주소검색" name="common">
        <DaumPostcode onComplete={handleComplete} />
        <Button variant="light" className="mr-2" onClick={() => {
          handleClosePostModal()
        }} style={{ position: "absolute", top: 0, right: 0 }} >X</Button>
      </Modal>

      {/* 배송지 목록 */}
      <Modal isOpen={isAddressModalOpen} onClose={handleCloseAddressModal} title="배송지 목록" name="list">
        {addressList.map((item) => (
          <Card style={{ textAlign: "left" }} key={item.dlvySptNo} className="mb-4">
            <Card.Header>{item.dlvySptNm}</Card.Header>
            <Card.Body>
              <Card.Text>
                {item.lnmAdres} &nbsp;&nbsp;{item.dtlAdres} ({item.roadAdres})
              </Card.Text>
              <Card.Text>{item.receiveName}&nbsp;&nbsp;&nbsp;{item.receivePhone}</Card.Text>
              <Button
                variant="primary"
                size="sm"
                style={{ backgroundColor: "#F39802", border: 0 }}
                onClick={() => {
                  // toast.success("배송지를 선택 하셨습니다.", {
                  //   position: "top-right",
                  //   duration: 2000,
                  //   style: {
                  //     marginTop: "100px",
                  //     background: "#F39802",
                  //     color: "#fff",
                  //     fontSize: "14px",
                  //     fontWeight: "bold",
                  //   },
                  //   iconTheme: {
                  //     primary: 'white',
                  //     secondary: 'black',
                  //   },
                  // });
                  successToast("배송지를 선택하셨습니다.")
                  handleSelectAddress(item);
                }}
              >
                배송지 선택
              </Button>
              <Button
                variant="light"
                size="sm"
                style={{ position: "absolute", right: 0, marginRight: "10px", color: "#F39802", border: "1px solid #F39802" }}
                /**
                 * todo
                 */
                onClick={() => {
                  setCheckDel(item.dlvySptNo);
                  setIsDeleteAddress(true);
                }}
              >
                삭제
              </Button>
            </Card.Body>
          </Card>
        ))
        }
        <Button variant="light" className="mr-2" onClick={() => {
          handleCloseAddressModal()
        }} style={{ position: "absolute", top: 0, right: 0 }} >X</Button>
      </Modal>

      {/* 결제하기 버튼 */}
      <Row className="justify-content-center">
        <Col md={6}>
          <Button
            variant="primary"
            size="lg" block="true"
            style={{ backgroundColor: "#F39802", border: 0 }}
            onClick={() => {
              btnBuy()
            }}>
            결제하기
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Order;
