import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, FormControl } from "react-bootstrap";
import {
  FaShoppingCart,
  FaClock,
  FaSearch
} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

function MyPage() {
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userInfo = useSelector(state => state.user) ?? [];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ordList, setOrdList] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [ordDtlList, setOrdDtlList] = useState([]);
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      getOrdList(pageSize);
    }
  }, []);

  const getOrdList = async (pageSize) => {
    setPageSize(pageSize);
    try {
      const cmpnyNo = JSON.parse(sessionStorage.getItem("user")).cmpnyNo;
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordCmpnyNo: cmpnyNo,
          offset: 0,
          pageNumber: 0,
          pageSize: pageSize,
          paged: true
        })
      });
      const data = await response.json();
      console.log(data);
      const dummy = data.content;
      const newList = await Promise.all(dummy);
      setOrdList(newList);

      const newListPromises = newList.map(async (item) => {
        const response2 = await fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordNo: item.ordNo,
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true
          })
        });
        const data2 = await response2.json();
        return [...data2.content];
      });

      const newPrdList = await Promise.all(newListPromises);
      const filteredArr = newPrdList.filter(newList => newList.length !== 0);
      setOrdDtlList(filteredArr);
      filteredArr.map((arr) => {
        arr.map((obj) => {
          if (obj.prdNo.includes("_")) {
            getOption(obj);
          }
        })
      })
    } catch (error) {
      console.error(error);
    }
  }

  // const formatPhoneNumber = (phoneNumberString) => {
  //   console.log(phoneNumberString);
  //   const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  //   const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  //   if (match) {
  //     return match[1] + '-' + match[2] + '-' + match[3];
  //   }
  //   return null;
  // }

  const renderStatus = (status) => {
    if (status === "OWS011") {
      return "결제전";
    } else if (status === "OWS012") {
      return "결제완료";
    } else if (status === "OWS013") {
      return "출고준비";
    } else if (status === "OWS014") {
      return "배송중";
    } else if (status === "OWS015") {
      return "배송완료";
    } else {
      return "정보가 없습니다.";
    }
  }

  const clickCard = (status, ordNo) => {
    let orderNum = [];
    orderNum.push(ordNo);
    if (status === "OWS011") {
      navigate("/order", {
        state: { orderNum: orderNum }
      })
    } else if (status === "OWS012") {
      navigate("/order-detail", {
        state: { orderNum: orderNum }
      })
    } else if (status === "OWS013") {
    } else if (status === "OWS014") {
    } else if (status === "OWS015") {
    } else {
      return "정보가 없습니다.";
    }
  }

  const handleClick = async (item, item2) => {
    let infoCmpny = "";
    let pdStock = "";
    const prdNo = item.prdNo.split("_")[0];

    try {
      const response = await fetch(`https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${item2.bcncNo}`, {
        method: "POST",
        headers: {
          accept: "*/*"
        }
      });
      const data = await response.json();
      infoCmpny = data;

      const response2 = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          cmpnyNo: infoCmpny.cmpnyNo,
          klightingYn: 'Y',
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      })
      const data2 = await response2.json();
      pdStock = data2.content[0];
    } catch (error) {
      console.error(error);
    }
    const product = {
      brand: pdStock.brand,
      cmpnyNo: pdStock.cmpnyNo,
      infoCmpny: infoCmpny,
      pdCmPrice: item.cost,
      prdImgFlpth: item.repImg,
      prdNo: prdNo,
      prductNm: item.prductNm,
    }
    navigate("/product-detail", {
      state: { product: product }
    })
  }

  const ordSearch = (event) => {
    event.preventDefault();
    const query = event.target.ordSearch.value;
    navigate("/mypage-search-result", {
      state: { query: query }
    });
  }

  const getOption = async (item) => {
    const prdNo = item.prdNo.split("_")[0];
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v1/prdOptNew", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          delYn: "N",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      });
      const data = await response.json();
      console.log(data);
      setOptionList(data.content);
    } catch (error) {
      console.error(error);
    }
  }

  const renderOption = (item) => {
    if (item.prdNo.includes("_")) {
      const optNo = item.prdNo.split("_")[1];
      let optNm = "";
      optionList.map((obj) => {
        if (obj.optNo == optNo) {
          optNm = obj.optNm + " / ";
        }
      })
      return optNm;
    }
  }

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Header>
              {userInfo.name}님, 안녕하세요!
            </Card.Header>
            <Card.Body>
              <p>이름: {userInfo.name}</p>
              <p>이메일: {userInfo.email}</p>
              <p>연락처: {userInfo.moblphonNo}</p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant="primary" style={{ backgroundColor: "#F39802", border: 0 }}>정보 수정</Button>
                <Button
                  variant="primary"
                  style={{ backgroundColor: "#F39802", border: 0 }}
                  onClick={() => {
                    dispatch({ type: "LOGOUT" });
                  }}
                >
                  로그아웃
                </Button>
              </div>
            </Card.Body>
          </Card>

          {/* <Card className="mb-4">
            <Card.Header>
              <FaTicketAlt /> 나의 쿠폰
            </Card.Header>
            <Card.Body>
              <p>적립금: 10,000원</p>
              <Button variant="primary">쿠폰 확인</Button>
            </Card.Body>
          </Card> */}
        </Col>

        <Col md={8}>
          <Form style={{ marginBottom: "20px" }} onSubmit={ordSearch}>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <div style={{ marginRight: "10px" }}>주문 검색</div>
              <div className="position-relative">
                <FormControl
                  type="search"
                  name="ordSearch"
                  id="search"
                  placeholder="검색"
                  aria-label="Search"
                  style={{ width: "275px", paddingRight: "40px" }}
                />
                <Button
                  variant="link"
                  type="submit"
                  className="position-absolute"
                  style={{
                    top: "50%",
                    right: "0px",
                    transform: "translateY(-50%)",
                    color: "#F39802"
                  }}
                >
                  <FaSearch />
                </Button>
              </div>
            </div>
          </Form>
          <Card className="mb-4">
            <Card.Header>
              <FaShoppingCart /> 주문 내역
            </Card.Header>
            <Card.Body>
              {
                ordDtlList.map((item, i) => (
                  <Card className='mb-4' key={ordList[i].ordNo}>
                    <Card.Header as="h6" onClick={() => {
                      clickCard(ordList[i].progrsSttus, item[0].ordNo);
                    }} style={{ cursor: "pointer" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p className='m-0'><span style={{ whiteSpace: "nowrap" }}>{ordList[i].dedtDe}</span></p>
                        <p className='m-0' style={{ whiteSpace: "nowrap" }}>{renderStatus(ordList[i].progrsSttus, item[0].ordNo)}</p>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <ul className='list-group'>

                        <p style={{ color: "rgb(153, 153, 153)", textAlign: "left" }}>{ordList[i].bcncNm}</p>
                        {
                          ordDtlList[i].map((item, index) => (
                            <div key={index} className="d-flex mb-2 mt-2 justify-content-between">
                              <div style={{ cursor: "pointer" }}>
                                <img onClick={() => {
                                  handleClick(item, ordList[i]);
                                }} style={{ width: "80px" }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.repImg}`} />
                              </div>
                              <div style={{ textAlign: "right", marginLeft: "10px" }}>
                                <p style={{ cursor: "pointer" }} onClick={() => {
                                  handleClick(item, ordList[i]);
                                }}>{item.prductNm}</p>
                                <p>{renderOption(item)} {(item.cost).toLocaleString()}원 &#183;  <span>{item.qy}개</span></p>
                              </div>
                            </div>
                          ))
                        }
                      </ul>
                    </Card.Body>
                    <Card.Footer>
                      <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <p className='m-0'>주문번호: {ordList[i].ordNo}</p>
                      </div>
                    </Card.Footer>
                  </Card>
                ))
              }
            </Card.Body>
            <Button
              style={{ backgroundColor: "#F39802", border: 0 }}
              onClick={() => {
                getOrdList(pageSize + 5);
              }}>더보기</Button>
          </Card>
          {/* <Card className="mb-4">
            <Card.Header>
              <FaEye /> 최근 본 상품
            </Card.Header>
            <Card.Body>
              <ul className="list-group" style={{ marginBottom: '1em' }}>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <p>상품명: MacBook Pro 2022</p>
                    <p>가격: 2,500,000원</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <p>상품명: iPad Pro 2022</p>
                    <p>가격: 1,200,000원</p>
                  </div>
                </li>
              </ul>
              <Button variant="primary">최근 본 상품 모두 보기</Button>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
    </Container >
  );
}

export default MyPage;
