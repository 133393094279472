import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Image } from 'react-bootstrap';
import Modal from "../common/Modal";
import { useNavigate } from "react-router-dom";
import { BsSend } from "react-icons/bs";
import { SiSlickpic } from "react-icons/si";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { IoPersonCircleOutline } from "react-icons/io5";
import Loading from '../common/Loading';
import dayjs from 'dayjs'
import { useSelector } from "react-redux";
import BoardRecomment from './BoardRecomment';
import Slider from "react-slick";

const Board = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [isBoardOpen, setIsBoardOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [feedList, setFeedList] = useState([]);
  const [pageSize, setPageSize] = useState(5);



  useEffect(() => {
    getFeed(pageSize);
  }, [])


  // 게시글 조회
  const getFeed = async (pageSize) => {
    setPageSize(pageSize);
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/FeedMgmt/feedList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gbn: "klighting",
          delYn: 'N',
          offset: 0,
          pageNumber: 0,
          pageSize: pageSize,
          paged: true
        })
      });
      const data = await response.json();
      const dummy = data.content;
      const addRecomment = dummy.map((item) => getFeedRecomment(item));
      const newFeed = await Promise.all(addRecomment);
      console.log(newFeed);
      setFeedList(newFeed);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // 게시글 댓글 조회
  const getFeedRecomment = async (item) => {
    const feedNo = item && item.feedNo;
    return fetch("https://cloud.1472.ai:18443/api/v2/FeedMgmt/feedRecomment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        feedNo: feedNo,
        offset: 0,
        pageNumber: 0,
        pageSize: 3,
        paged: false
      })
    })
      .then((response) => response.json())
      .then((data) => {
        const Recomment = data.content;
        return { ...item, Recomment };
      })
      .catch((error) =>
        console.error(error)
      )
  };

  const handleCloseBoardModal = () => {
    // setIsBoardOpen(false);
    console.log("??");
  }

  const handleCloseModal = () => {
    setIsLoginModalOpen(false);
  }

  // 게시글 작성
  const handleBoardSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const fileInput = form.querySelector('input[type="file"]');
    const textInput = form.querySelector('input[type="text"]');

    // 파일 선택 여부 확인
    if (fileInput.files.length === 0) {
      console.log('파일이 선택되지 않았습니다.');
      return;
    }

    // 내용 입력 확인
    const textValue = textInput.value.trim();
    if (textValue === '') {
      console.log('내용이 입력되지 않았습니다.');
      return;
    }

    // FormData 생성
    const formData = new FormData();

    for (let i = 0; i < fileInput.files.length; i++) {
      formData.append('images', fileInput.files[i]);
    }
    formData.append('ids', 'P');

    uploadImg(formData, textValue);
  };

  // 이미지 업로드
  const uploadImg = async (formData, textValue) => {
    try {
      const response = await fetch("/api/imgUpload", {
        method: "POST",
        body: formData
      });
      const data = await response.text();
      console.log(JSON.parse(data));
      postFeed(JSON.parse(data), textValue);
    } catch (error) {
      console.error(error);
    }
  }

  // 글 작성 api
  const postFeed = async (url, content) => {
    console.log(content);
    let urls = [];
    url.forEach(ele => {
      urls.push(ele.fileName);
    });
    console.log(urls);
    const mberNm = JSON.parse(sessionStorage.getItem('user')).name;
    const mberNo = JSON.parse(sessionStorage.getItem('user')).mberNo;
    const mberId = JSON.parse(sessionStorage.getItem('user')).userName;
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/FeedMgmt/feed", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mberNo: mberNo,
          mberNm: mberNm,
          content: content,
          imgUrl: JSON.stringify(urls),
          delYn: "N",
          gbn: "klighting",
          createMberId: mberId,
          updateMberId: mberId,
        })
      });
      const data = await response.json();
      console.log(data);
      getFeed(pageSize);
      //  글 작성 완료후 글작성모달 초기화 테스트하기
      setPreviewImage(null);
      setIsBoardOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  // 댓글 작성 버튼
  const handleCommentSubmit = (feedNo) => (event) => {
    event.preventDefault();
    if (!isLoggedIn) {
      setIsLoginModalOpen(true);
    } else {
      const form = event.currentTarget;
      const input = form.elements.comment;
      if (form.checkValidity() === false) {
        console.log("유효성검사실패");
      } else {
        postRecomment(feedNo, input.value);
        input.value = "";
      }
    }
  }

  // 댓글 작성
  const postRecomment = async (feedNo, content) => {
    const nowTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const mberNm = JSON.parse(sessionStorage.getItem('user')).name;
    const mberNo = JSON.parse(sessionStorage.getItem('user')).mberNo;
    const mberId = JSON.parse(sessionStorage.getItem('user')).userName;
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/FeedMgmt/feedRecomment", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          feedNo: feedNo,
          mberNo: mberNo,
          mberNm: mberNm,
          content: content,
          delYn: "N",
          createMberId: mberId,
          updateMberId: mberId,
          createDt: nowTime,
          updateDt: nowTime,
        })
      });
      const data = await response.json();
      console.log(data);
      getFeed(pageSize);
    } catch (error) {
      console.error(error);
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setPreviewImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <Row style={{ marginBottom: '1em' }}>
        <div
          style={{
            backgroundColor: "rgb(243, 152, 2)", border: "rgb(243, 152, 2)", borderRadius: "50%", padding: "15px",
            position: "fixed", bottom: 80, right: "1rem", cursor: "pointer", zIndex: "1"
          }}
        >
          <HiOutlinePencilAlt size={35} style={{ color: "white" }}
            onClick={() => {
              if (!isLoggedIn) {
                setIsLoginModalOpen(true);
              } else {
                setIsBoardOpen(true);
              }
            }} />
        </div>
        {
          isLoading ? (
            <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
              <Loading />
            </Col>
          ) : (
            <>
              {
                feedList.map((item) => (
                  <Col key={item.feedNo} xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
                    <Card style={{ width: '30rem' }}>
                      <Card.Header style={{ display: "flex" }}>
                        <IoPersonCircleOutline size={40} />
                        <span style={{ display: "flex", alignContent: "center", flexWrap: "wrap", marginLeft: "5px" }}><span style={{ fontWeight: "bold" }}>{item.createMberId}</span></span>
                      </Card.Header>
                      <Slider dots={true}>
                        {JSON.parse(item.imgUrl).map((image, index) => (
                          <div key={index}>
                            <Card.Img variant="top" src={`https://cloud.1472.ai:18443/images/cloud/portfolio/${image}`} style={{ width: "100%", height: "478px", objectFit: "contain" }} />
                          </div>
                        ))}
                      </Slider>
                      <Card.Body style={{ textAlign: "left" }}>
                        <Card.Text style={{ marginTop: '3rem', marginBottom: '3rem'}}>
                          {item.content}
                        </Card.Text>
                        <hr style={{ width: "100%", margin: 0, marginBottom: "20px" }} />
                        <BoardRecomment recomment={item.Recomment} />
                      </Card.Body>
                      <Card.Footer style={{ backgroundColor: "white" }}>
                        <Form onSubmit={handleCommentSubmit(item.feedNo)} style={{ display: "flex", justifyContent: "space-between" }}>
                          <Form.Group style={{ display: "inline-block", width: "23rem" }}>
                            <Form.Control
                              style={{ border: "none" }}
                              required
                              name='comment'
                              type="text"
                              placeholder="댓글 달기 ..."
                            />
                          </Form.Group>
                          <Button type='submit' style={{ backgroundColor: "rgb(243, 152, 2)", border: "rgb(243, 152, 2)" }}>
                            <BsSend />
                          </Button>
                        </Form>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))
              }
            </>
          )
        }
        <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
          <Button
            style={{ backgroundColor: "#F39802", border: 0 }}
            onClick={() => {
              getFeed(pageSize + 5);
            }}>더보기</Button>
        </Col>
      </Row>

      {/* 글작성 */}
      <Modal isOpen={isBoardOpen} onClose={handleCloseBoardModal} title="게시글 작성" name="board">
        <Card >
          <Card.Body>
            <Form className="text-left" onSubmit={handleBoardSubmit}>
              <Form.Group className="mb-3" style={{ display: "flex", flexDirection: "column" }}>
                {/* <Form.Label>사진</Form.Label> */}
                {previewImage ? (
                  <Image src={previewImage} alt="미리보기" thumbnail style={{ width: "50%", margin: '0 auto' }} />
                ) : (
                  <div style={{
                    minHeight: "300px", display: "flex", flexDirection: "column",
                    alignItems: "center", justifyContent: "center"
                  }}>
                    <SiSlickpic size={80} />
                    사진을 업로드하세요
                  </div>
                )}
                <Form.Control
                  type="file"
                  onChange={handleImageChange}
                  style={{ marginBottom: "2rem", border: "none" }}
                  multiple
                />
                <Form.Control
                  type="text"
                  placeholder="내용"
                  name='board'
                  required
                />
              </Form.Group>
              <Button
                style={{ backgroundColor: "#F39802", border: 0, float: "right" }}
                type="submit"
              >게시</Button>
            </Form>
          </Card.Body>
        </Card>
        <Button variant="light" className="mr-2" onClick={() => {
          setIsBoardOpen(false);
        }}
          style={{ position: "absolute", top: 0, right: 0 }}>
          X
        </Button>
      </Modal>

      <Modal isOpen={isLoginModalOpen} onClose={handleCloseModal} title="로그인" width="400px" maxWidth="90%">
        <p>로그인 해주세요.</p>
        <Button
          variant="primary"
          className="mr-2"
          onClick={() => {
            navigate('/login');
          }}
        >
          확인
        </Button>
      </Modal>

    </Container >
  );
};

export default Board;