import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "../common/Modal";
import { toast } from "react-hot-toast";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useSelector } from "react-redux";
import dayjs from 'dayjs'

function ProductDetailInfo({ prdDetail }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCartLocation, setIsCartLocation] = useState(false);
  const [isBuyModal, setIsBuyModal] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [dtlList, setDtlList] = useState([]);
  const [option, setOption] = useState([]);
  // const [selectedOption, setSelectedOption] = useState("");
  const [optionList, setOptionList] = useState([]);
  const defaultOption = "";

  useEffect(() => {
    getPdOption();
    if (0 == dtlList.length) {
      console.log("전송x")
    } else {
      console.log("전송o")
      insertOrd();
    }
  }, [dtlList]);

  const handleAddCart = () => {
    const mberNo = JSON.parse(sessionStorage.getItem('user')).mberNo;
    const cmpnyNo = JSON.parse(sessionStorage.getItem('user')).cmpnyNo;
    const cmpnyNm = JSON.parse(sessionStorage.getItem('user')).cmpnyNm;
    if (optionList.length > 0) {
      option.map((item) => {
        fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "delYn": "N",
            "gubun": "E",
            "imgUrl": prdDetail.prdImgFlpth,
            "mberNo": mberNo,
            "ordCmpnyNm": cmpnyNm,
            "ordCmpnyNo": cmpnyNo,
            "prdNm": prdDetail.prductNm,
            "prdNo": prdDetail.prdNo + "_" + item.optNo,
            "prdPrice": prdDetail.pdCmPrice,
            "qty": item.qty,
            "bcncCmpnyNm": prdDetail.infoCmpny.cmpnyNm,
            "bcncCmpnyNo": prdDetail.cmpnyNo,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            successToast("장바구니에 상품이 추가되었습니다.");
          })
          .catch((error) => console.error(error));
      })
    } else {
      fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "delYn": "N",
          "gubun": "E",
          "imgUrl": prdDetail.prdImgFlpth,
          "mberNo": mberNo,
          "ordCmpnyNm": cmpnyNm,
          "ordCmpnyNo": cmpnyNo,
          "prdNm": prdDetail.prductNm,
          "prdNo": prdDetail.prdNo,
          "prdPrice": prdDetail.pdCmPrice,
          "qty": selectedQuantity,
          "bcncCmpnyNm": prdDetail.infoCmpny.cmpnyNm,
          "bcncCmpnyNo": prdDetail.cmpnyNo,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          successToast("장바구니에 상품이 추가되었습니다.");
        })
        .catch((error) => console.error(error));
    }

  };

  const getPdOption = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v1/prdOptNew", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdDetail.prdNo,
          delYn: "N",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      });
      const data = await response.json();
      console.log(data);
      setOptionList(data.content);
    } catch (error) {
      console.error(error);
    }
  }

  const locationLogin = () => {
    navigate('/login');
  }

  const handleOpenModal = () => {
    if (!isLoggedIn) {
      setIsLoginModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = (cart) => {
    setIsModalOpen(false);
    if (cart === true) {
      setIsCartLocation(true);
    }
  };

  const handleCloseCartModal = () => {
    setIsCartLocation(false);
  }

  const handleCloseBuyModal = () => {
    setIsBuyModal(false);
  }

  const handleQuantityIncrease = () => {
    setSelectedQuantity((prev) => prev + 1);
  };

  const handleQuantityDecrease = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity((prev) => prev - 1);
    }
  };


  const handleOptionIncrease = (optNo) => {
    setOption((list) =>
      list.map((item) =>
        item.optNo == optNo ? { ...item, qty: item.qty + 1 } : item
      ))
  };

  const handleOptionDecrease = (optNo, qty) => {
    if (qty > 1) {
      setOption((list) =>
        list.map((item) =>
          item.optNo == optNo ? { ...item, qty: item.qty - 1 } : item
        ))
    }
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    option.map((item) => {
      totalPrice = totalPrice + (item.price * item.qty);
    })
    return (totalPrice).toLocaleString();
  };

  const getTotalPrice2 = () => {
    const price = prdDetail.pdCmPrice || 0;
    return (price * selectedQuantity).toLocaleString();
  }

  const getOptionPrice = (item) => {
    const price = prdDetail.pdCmPrice || 0;
    return (price * item.qty).toLocaleString();
  }

  // 주문서 api
  const insertOrd = () => {
    const cmpnyNo = JSON.parse(sessionStorage.getItem('user')).cmpnyNo;
    const name = JSON.parse(sessionStorage.getItem('user')).name
    const nowTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    fetch("https://erp.1472.ai:28443/api/v1/ord", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ordVo: {
          dlvySptAdres: "",
          // dlvySptNm: '',
          dtlList: dtlList,
          ordCmpnyNo: cmpnyNo, // 주문회사번호
          ordMberNo: 1, // 주문회원번호
          wrter: 1, // 작성자
          ordSttusCl: "ORDS02", // 주문상태분류
          progrsSttus: "OWS011", // 진행상태
          writeDt: nowTime, // 작성일시
          ordDt: nowTime, // 주문일시
          dedtDe: nowTime, // 납기일자
          bcncNo: prdDetail.cmpnyNo, // 거래처번호
          bcncNm: prdDetail.infoCmpny.cmpnyNm, // 거래처명
          bcncTelno: prdDetail.infoCmpny.telno, // 거래처전화번호
          sptNm: "주소테스트2", // 현장명 넣기
          sptAdres: "현장주소 테스트", // 현장주소 넣기
          dlvyMth: "택배", // 배송방법
          dlvyCt: "선불", // 배송비
          rcpnt: name, // 수령자
          dept: "없음", // 부서
          vatCl: "VATS03", // 부가세분류
          demandTot: 0, // 출고액합계
          trnsprtDvyfgAm: 0, // 운송,납품액
          bndePrductCl: "전체", // 일괄제품분류
          bndeMargin: 0, // 일괄마진
          bndeNego: 0, // 일괄네고
          sumry: "1", // 적요
          ordCours: "OCR001", // 주문경로
          ordCrcmstncs: "1", // 주문경위
          buyNo: 0, // 매입관리번호
          selngNo: 0, // 매출관리번호
          faxYn: "N", // 팩스여부
          prtYn: "N", // 출력여부
          dlivyYn: "N", // 출고여부
          bilclctYn: "N", // 수금여부
          delYn: "N", // 삭제여부
          schRegYn: "N" // 일정등록여부
        }
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const ordNo = data.message
        let orderNum = [];
        orderNum.push(ordNo);
        navigate("/order", {
          state: {
            orderNum: orderNum
          },
        });
      })
      .catch((error) => console.error(error));
  }

  const insertOrdList = () => {
    if (option.length > 0) {
      option.map((item) => {
        const newData = {
          prdNo: prdDetail.prdNo + "_" + item.optNo,
          prductNm: prdDetail.prductNm,
          setNo: 0,
          totAmount: (prdDetail.pdCmPrice * item.qty),
          prductCl: "",
          qy: item.qty,
          cost: prdDetail.pdCmPrice,
          m: 0,
          n: 0,
          supplyAmt: 0,
          tax: 0,
          ordQy: 0,
          bomSetSe: "1",
          asPd: 0,
          prmpc: 0,
          prmpcSm: 0,
          profitAmt: 0,
          selngEntrpsNo: 0,
          socNo: 0,
          dlivMatter: "",
          vendnm: "",
          delYn: "N",
          repImg: prdDetail.prdImgFlpth,
          selngDataNo: 0,
          dlvPrpNo: "",
          ordReqNo: 0,
          setYn: "N",
        };
        setDtlList((list) => [...list, newData])
      })
    } else {
      const newData = {
        prdNo: prdDetail.prdNo,
        prductNm: prdDetail.prductNm,
        setNo: 0,
        totAmount: (prdDetail.pdCmPrice * selectedQuantity),
        prductCl: "",
        qy: selectedQuantity,
        cost: prdDetail.pdCmPrice,
        m: 0,
        n: 0,
        supplyAmt: 0,
        tax: 0,
        ordQy: 0,
        bomSetSe: "1",
        asPd: 0,
        prmpc: 0,
        prmpcSm: 0,
        profitAmt: 0,
        selngEntrpsNo: 0,
        socNo: 0,
        dlivMatter: "",
        vendnm: "",
        delYn: "N",
        repImg: prdDetail.prdImgFlpth,
        selngDataNo: 0,
        dlvPrpNo: "",
        ordReqNo: 0,
        setYn: "N",
      };
      setDtlList((list) => [...list, newData])
    }
  }

  const handleCheckboxChange = (e) => {
    if (option.some((item) => item.optNo == e.nativeEvent.target.value)) {
      errorToast("이미 선택한 옵션입니다.")
    } else {
      setOption((list) => [...list, { optNo: e.nativeEvent.target.value, qty: 1, price: prdDetail.pdCmPrice }])
    }
    renderOption();
  };

  const deleteOption = (optNo) => {
    const updatedOptionList = option.filter((item) => item.optNo !== optNo);
    setOption(updatedOptionList);
  }

  const renderOption = () => {
    return (
      option.map((item) => (
        <div key={item.optNo}>
          <div style={{ display: "flex", justifyContent: 'space-around', alignItems: "center" }}>
            <span style={{ width: "120px" }}>
              {optionList.map((item2) => {
                if (item2.optNo == item.optNo) {
                  return <span key={item2.optNo}>{item2.optNm}</span>;
                } else {
                  return null;
                }
              })}
            </span>
            <div>
              <Button
                variant="outline-secondary"
                className="mr-2"
                onClick={() => {
                  handleOptionDecrease(item.optNo, item.qty)
                }}
              >
                <AiOutlineMinus />
              </Button>
              <span>{item.qty}</span>
              <Button
                variant="outline-secondary"
                className="ml-2"
                onClick={() => {
                  handleOptionIncrease(item.optNo)
                }}
              >
                <AiOutlinePlus />
              </Button>
            </div>
            {getOptionPrice(item)}원
            <Button variant="light" style={{ padding: "0" }}
              onClick={() => {
                deleteOption(item.optNo);
              }}
            >x</Button>
          </div>
          <hr />
        </div>
      ))
    )
  }

  const errorToast = (message) => {
    toast.error(message, {
      position: "top-center",
      duration: 2000,
      style: {
        marginTop: "50px",
        background: "#ff4040",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "bold",
      },
    });
  }

  const successToast = (message) => {
    toast.success(message, {
      position: "top-right",
      duration: 2000,
      style: {
        marginTop: "100px",
        background: "#F39802",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "bold",
      },
      iconTheme: {
        primary: 'white',
        secondary: 'black',
      },
    });
  }

  return (
    <Card className="h-100">
      <Card.Body>
        <Card.Title className="text-left">{prdDetail.prductNm}</Card.Title>
        <Card.Text className="mb-2 text-left" style={{ color: "rgb(153, 153, 153)" }}>
          브랜드: {prdDetail.brand ?? '없음'}
        </Card.Text>
        <Card.Text className="mb-2 text-right" style={{ fontSize: "20px" }}>
          {(prdDetail?.klightingPdCmPrice ?? prdDetail?.pdCmPrice).toLocaleString()}원
        </Card.Text>
        <hr />
        <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
          {
            optionList.length > 0 ? (
              <Form.Select style={{ width: "35%" }} className="form-control form-control-sm"
                onChange={handleCheckboxChange}
                value={defaultOption}>
                <option value="">옵션선택</option>
                {
                  optionList.map((item) => (
                    <option key={item.optNo} value={item.optNo}>{item.optNm}</option>
                  ))
                }
              </Form.Select>
            ) : (
              <>
                <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
                  <span className="mr-2">수량:</span>
                  <Button
                    variant="outline-secondary"
                    className="mr-2"
                    onClick={handleQuantityDecrease}
                  >
                    <AiOutlineMinus />
                  </Button>
                  <span>{selectedQuantity}</span>
                  <Button
                    variant="outline-secondary"
                    className="ml-2"
                    onClick={handleQuantityIncrease}
                  >
                    <AiOutlinePlus />
                  </Button>
                </div>
              </>
            )
          }
        </div>
        {
          option.length > 0 ? (
            <><hr /></>
          ) : (
            <></>
          )
        }
        {/* <div className="d-flex align-items-center" style={{ justifyContent: 'end' }}>
          <span className="mr-2">수량:</span>
          <Button
            variant="outline-secondary"
            className="mr-2"
            onClick={handleQuantityDecrease}
          >
            <AiOutlineMinus />
          </Button>
          <span>{selectedQuantity}</span>
          <Button
            variant="outline-secondary"
            className="ml-2"
            onClick={handleQuantityIncrease}
          >
            <AiOutlinePlus />
          </Button>
        </div> */}
        <div>{renderOption()}</div>
        <Card.Text className="mb-5 mt-5 text-right" style={{ fontSize: "20px" }}>
          {
            option.length > 0 ? <>총 제품금액 {getTotalPrice()} 원</> : <>총 제품금액 {getTotalPrice2()} 원</>
          }
        </Card.Text>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="장바구니" name="common">
          <p>장바구니에 상품을 추가하시겠습니까?</p>
          <Button variant="light"
            style={{ color: "#F39802", border: "1px solid #F39802" }}
            className="mr-2" onClick={handleCloseModal}>
            취소하기
          </Button>
          <Button
            variant="primary"
            className="mr-2"
            style={{ backgroundColor: "#F39802", border: 0 }}
            onClick={() => {
              handleAddCart();
              handleCloseModal(true);
            }}
          >
            추가하기
          </Button>
        </Modal>

        <Modal isOpen={isCartLocation} onClose={handleCloseCartModal} title="장바구니" name="common">
          <p>장바구니로 이동하시겠습니까?</p>
          <Button
            variant="light"
            style={{ color: "#F39802", border: "1px solid #F39802" }}
            className="mr-2"
            onClick={handleCloseCartModal}
          >
            계속 쇼핑하기
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#F39802", border: 0 }}
            onClick={() => {
              navigate('/cart');
            }}
          >
            이동하기
          </Button>
        </Modal>

        <Modal isOpen={isBuyModal} onClose={handleCloseBuyModal} title="장바구니" name="common">
          <p>구매하시겠습니까?</p>
          <Button
            variant="light"
            style={{ color: "#F39802", border: "1px solid #F39802" }}
            className="mr-2"
            onClick={handleCloseBuyModal}
          >
            취소
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#F39802", border: 0 }}
            onClick={() => {
              insertOrdList();
            }}
          >
            확인
          </Button>
        </Modal>

        <Modal isOpen={isLoginModalOpen} onClose={handleCloseModal} title="로그인" width="400px" maxWidth="90%">
          <p>로그인 해주세요.</p>
          <Button
            variant="primary"
            className="mr-2"
            onClick={() => {
              locationLogin();
            }}
          >
            확인
          </Button>
        </Modal>

        <Button
          variant="light"
          style={{ color: "#F39802", border: "1px solid #F39802" }}
          className="mr-3"
          onClick={() => {
            if (optionList.length > 0) {
              if (option.length > 0) {
                handleOpenModal();
              } else {
                errorToast("옵션을 선택하세요.")
              }
            } else {
              handleOpenModal();
            }
          }}
        >
          장바구니
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: "#F39802", border: 0 }}
          onClick={() => {
            if (!isLoggedIn) {
              setIsLoginModalOpen(true);
            } else {
              if (optionList.length > 0) {
                if (option.length > 0) {
                  setIsBuyModal(true);
                } else {
                  errorToast("옵션을 선택하세요.")
                }
              } else {
                setIsBuyModal(true);
              }
            }
          }}
        >
          바로구매
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ProductDetailInfo;
